import React, { useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import Folder from '../components/folders/Folder'
import { useGetAllFolders, useGetFolder, useGetFolderFiles } from '../hooks/mutations'
import { useParams } from 'react-router-dom'
import Loading from '../components/ui/Loading'

const FolderPage = () => {
  const isLive = process.env.REACT_APP_CURRENT_ENV !== 'STAGING';
  const { reactFolderId } = useParams();
  const [folderId, setFolderId] = useState(isLive ? null : reactFolderId);
  const [folderName, setFolderName] = useState(isLive ? null : 'Temp Name');

  console.log('folderId', folderId)

  // Get the script element by its id
  const scriptElement = document.getElementById('django-folder');
  if (scriptElement && !folderId) {
    // Access the content of the script element
    const djangoFolderString = scriptElement?.textContent;
  
    // Parse the JSON data
    const djangoFolder = JSON.parse(djangoFolderString);

    // Set the survey id state from the JSON data
    setFolderId(djangoFolder.id)
    setFolderName(djangoFolder.name)
  }

  const folder = useGetFolder(folderId);
  console.log('folder', folder);
  return (
    // <MainPageTemplate title="CRM">
    folder
      ? <Folder folder={folder} />
      : <Loading />
    // </MainPageTemplate>
  )
}

export default FolderPage