import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import SocialPosts from '../components/social_posts/SocialPosts'

const SocialPostsPage = () => {
  return (
    <MainPageTemplate
      title="Social Posts"
      breadcrumbs={[
        'posts',
      ]}
    >
      <SocialPosts />
    </MainPageTemplate>
  )
}

export default SocialPostsPage