import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Box, Chip, IconButton } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useGetAllChannels, useGetAvailableChannels, deleteChannelConnection } from '../hooks/mutations';
import { DeleteSVG } from '../assets/icons';
import getChannelIcon from '../components/social_posts/SocialMediaCommons';


const SocialChannelsManagePage = () => {
  const theme = useTheme();
  const connected = useGetAllChannels();
  const available = useGetAvailableChannels();
  const [connectedChannels, setConnectedChannels] = useState(connected);
  const [availableChannels, setAvailableChannels] = useState(available);

  // const FetchChannels = async () => {
  //   const available = await useGetAvailableChannels();
  //   setConnectedChannels(connected);
  //   setAvailableChannels(available);
  // };

  useEffect(() => {
    if (available != null) {
      console.log('available in use e', available);
      setAvailableChannels([...available]);
    }
  }, [available]);

  useEffect(() => {
    if (connected != null) {
      console.log('connected in use e', connected);
      setConnectedChannels([...connected]);
    }
  }, [connected]);

  const handleDeleteConnection = async (channelId) => {
    const response = await deleteChannelConnection(channelId);
    if (response.status === 'success') {
      console.log('Channel disconnected successfully');
      // Update the connected channels state
      setConnectedChannels(connectedChannels.filter(channel => channel.provider_id !== channelId));
    }
  };

  if (availableChannels == null || connectedChannels == null) {
    return <Typography>Loading...</Typography>;
  }

  console.log('connectedChannelsInM', connectedChannels);
  console.log('availableChannelsInM', availableChannels);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Manage Social Media Channels</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Connected Channels:</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
          {connectedChannels.map((channel) => (
            <div key={channel.provider_id} style={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}>
              {getChannelIcon(channel.provider)}
              <Typography variant="body1">{channel.provider_name}</Typography>
              <a href={channel.profile_url} target="_blank" rel="noopener noreferrer">
                <Typography variant="body2" color="primary">View Profile</Typography>
              </a>
              <IconButton onClick={() => handleDeleteConnection(channel.provider)}>
                <DeleteSVG height={16} />
              </IconButton>
            </div>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Available Channels to Connect:</Typography>
        <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
          {availableChannels
            .filter(channel => !connectedChannels.some(connected => connected.provider === channel.provider))
            .map((channel) => (
              <Button
                key={channel.provider_id}
                variant="contained"
                color="primary"
                href={channel.url}
                startIcon={getChannelIcon(channel.provider)}
              >
                Connect {channel.name}
              </Button>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SocialChannelsManagePage;