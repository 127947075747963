import { useTheme } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadSVG } from '../../assets/icons';

const FileDropZone = (props) => {
  const { setFile, maxSize, multiple, readAsText, message, style } = props;
  const theme = useTheme();

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      if (readAsText) { // used when readAsText prop exists
        console.log('reading as text');
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          // Read the file as text
          reader.onload = (e) => {
            const jsonData = JSON.parse(e.target.result);
            if (multiple) {
              setFile((prevFiles) => [
                ...prevFiles,
                {
                  file: file,
                  text: jsonData
                }
              ])
            } else {
              setFile({
                file: file,
                text: jsonData
              })
            }
          };
          // Trigger the file reading
          reader.readAsText(file);
        });
      } else { // used when no "readAs" prop exists -- default usage
        console.log('reading as data url');
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();
          // Read the selected file as a blob and set it for preview
          reader.onload = (e) => {
            const blob = e.target.result;
            if (multiple) {
              setFile((prevFiles) => [
                ...prevFiles,
                {
                  file: file,
                  blob: blob
                }
              ])
            } else {
              setFile({
                file: file,
                blob: blob
              });
              // Do something with the blob, like displaying it in an image tag
              // For example, setBlob(blob) in your component state
            }
          };
          reader.readAsDataURL(file);
        });
      }
    }
  }, [setFile, readAsText, multiple]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpg', // Specify accepted file types (in this case, images)
    multiple: multiple,
    maxSize: maxSize,
  });

  return (
    <div {...getRootProps()} style={{width:'100%'}}>
      <input {...getInputProps()} />
      <Grid
        container
        variant="center"
        style={{
          flexDirection:'column',
          padding:24,
          minHeight:150,
          border: isDragActive ? `5px solid ${theme.palette.primary.main}` : '5px dashed #ccc',
          background: isDragActive ? `${theme.palette.primary.main}15` : 'transparent',
          cursor:'pointer',
          ...style
        }}
      >
        <UploadSVG width={40} />
        <Typography
          style={{
            textAlign:'center',
            marginTop:16,
            fontSize:20,
            lineHeight:1.2,
            color:'#777'
          }}
        >
          { message ? message : `Drag 'n' drop or click to select file` }
        </Typography>
      </Grid>
    </div>
  );
};

const dropZoneStyles = {
  border: '2px dashed #eee',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

export default FileDropZone;
