import React, { useEffect, useState } from 'react'
import { Dialog, Grid, IconButton, Typography } from '@mui/material';
import { DeleteSVG, FolderSVG } from '../../assets/icons';
import { DjangoComponents } from '../../templates/DjangoStyles';
import { deleteStorageFile, useGetFolderFiles } from '../../hooks/mutations';
import { useTheme } from '@emotion/react';

const Folder = (props) => {
  const { folder } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [reloadFiles, setReloadFiles] = useState(true);
  const incomingFiles = useGetFolderFiles({folderId:folder.id, page:page, pageSize:pageSize, reload:reloadFiles});
  const [fileCount, setFileCount] = useState(incomingFiles?.count);
  const [files, setFiles] = useState(incomingFiles?.results);

  console.log('incomingFiles', incomingFiles);
  console.log('files', files);
  console.log('pageSize', pageSize);
  console.log('page', page);
  console.log('reloadFiles', reloadFiles);

  useEffect(() => {
    if (incomingFiles) {
      console.log('incoming files changed');
      if (files && files.length) {
        console.log('setting files to append');
        setFiles(prev => [ ...prev, ...incomingFiles?.results]);
        setFileCount(incomingFiles?.count);
        setPageSize(10); // Reset page size to be sure it is at the default 10 page size
      } else {
        console.log('setting initial files');
        setFiles(incomingFiles?.results);
        setFileCount(incomingFiles?.count);
      }
    }
  }, [incomingFiles]);

  useEffect(() => {
    if (reloadFiles) {
      setReloadFiles(false);
    }
  }, [reloadFiles])

  const handleUpdateAfterDelete = (deletedFileId) => {
    // const newFiles = files.filter(x => x.id !== deletedFileId);
    // setFiles(newFiles);
    setReloadFiles(true);
    setPageSize(files.length);
  }

  const getNextPage = () => {
    if (incomingFiles?.next) {
      setPage(prev => prev + 1);
      setReloadFiles(true);
    }
  }

  return (
    <DjangoComponents inner>
      <Grid
        container
        sx={{
          // justifyContent:'center'
        }}
      >
        <Grid
          container
          sx={{
          }}
        >
          <Typography variant="h1">{folder?.name}</Typography>
        </Grid>
        <Grid
          container
          sx={{
            // maxWidth:{ts:'100%', md:600, lg:800, xl:900}
            // justifyContent:'center'
          }}
        >
          {
            files?.map(file => (
              <FileItem file={file} handleUpdateAfterDelete={handleUpdateAfterDelete} />
            ))
          }
          {incomingFiles?.next ? <LoadMore files={files} fileCount={fileCount} getNextPage={getNextPage} /> : null}
        </Grid>
        <Typography>{fileCount > 0 ? fileCount : 'No files have been uploaded to this folder yet.'}</Typography>
      </Grid>
    </DjangoComponents>
  )
}

export default Folder

const FileItem = ({ file, handleUpdateAfterDelete }) => {
  const theme = useTheme();
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [hover, setHover] = useState(false);

  const handleDeleteFile = async (e) => {
    e.stopPropagation();
    const fileObj = {
      folderId: file.folder,
      fileId: file.id
    }
    console.log('delete ', fileObj)
    const deleted = await deleteStorageFile(fileObj);
    if (deleted.status === "success") {
      handleUpdateAfterDelete(file.id)
    }
  }

  const handleOpenImageDialog = () => {
    setOpenImageDialog(true);
  }

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  }

  return (
    <Grid
      key={file.id}
      item
      sx={{
        p:1,
        width:{ts:300, xs:200}
      }}
      // ts={12}
      // xs={6}
      // sm={4}
      // md={3}
    >
      <Grid
        onClick={handleOpenImageDialog}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          position:'relative',
          boxShadow:'0 0 10px #00000020',
          p:2,
          borderRadius:4,
          background:hover ? `${theme.palette.primary.main}aa` : '#fff',
          background:hover ? `#ddd` : '#fff',
          transition:'.2s',
          cursor:'pointer'
        }}
      >
        <img
          src={file.file}
          style={{
            width:'100%',
            height:150,
            objectFit:'cover',
            borderRadius:8,
            opacity: hover ? .85 : 1,
          }}
        />
        <IconButton
          onClick={handleDeleteFile}
          sx={{
            position:'absolute',
            top:7,
            right:7,
            transform:hover ? 'scale(1)' : 'scale(0)',
            transition:'.2s',
            background:theme.palette.warning.main,
            '&&:hover':{
              background:theme.palette.warning.dark
            },
            borderRadius:3,
            width:40,
            height:40,
          }}
        >
          <DeleteSVG color1={"#fff"} width={16} />
        </IconButton>
      </Grid>
      <Typography
        sx={{
          textAlign:'center',
          width:'100%'
        }}
      >
        {file.name}
      </Typography>

      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        maxWidth="lg"
      >
        <img
          src={file.file}
          style={{
            width:'100%',
            maxHeight:'90vh',
            objectFit:'contain',
          }}
        />
      </Dialog>
    </Grid>
  )
}

const LoadMore = ({ files, fileCount, getNextPage }) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);

  return (
    <Grid
      item
      sx={{
        p:1,
        width:{ts:300, xs:200}
      }}
    >
      <Grid
        onClick={getNextPage}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          position:'relative',
          boxShadow:'0 0 10px #00000020',
          p:2,
          borderRadius:4,
          background:hover ? `${theme.palette.primary.main}aa` : '#fff',
          background:hover ? `#ddd` : '#fff',
          transition:'.2s',
          cursor:'pointer',
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          justifyContent:'center',
          // height:'100%',
          height:186
        }}
      >
        <Typography
          sx={{
            textAlign:'center',
            width:'100%'
          }}
        >
          Load More Images
        </Typography>
        <Typography
          sx={{
            textAlign:'center',
            width:'100%'
          }}
        >
          {files?.length} out of {fileCount} images
        </Typography>
      </Grid>
    </Grid>

  )
}