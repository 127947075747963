import React, { useEffect, useState, cloneElement } from 'react'
import { Button, Collapse, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useGetFilteredLists } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG,DeleteSVG, UsageSVG  } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { ButtonCustom } from '../ui/Buttons';
import { Link } from 'react-router-dom';
import { deleteList } from '../../hooks/mutations';
import ReusableModal from '../ui/ReusableModal';
import CustomTooltip from '../ui/CustomTooltip';
import DialogWarning from '../ui/DialogWarning';
import { DataGrid } from '@mui/x-data-grid';
import { mockData } from '../../lib/mockData';
import DialogCreate from '../ui/DialogCreate';
import Loading from '../ui/Loading';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import { TagButton } from '../ui/Tags';
import { subscriberStatusEnum } from '../../lib/Data';


const ListSubscribers = (props) => {
  const { incomingSubscribers, list } = props;
  const [reloadLists, setReloadLists] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
  });
  const [pages, setPages] = useState({page: 1, count:null, next:null, prev:null});
  const incomingLists = useGetFilteredLists({reload:reloadLists, filterBy:filterBy});
  const screenSize = useScreenSize();
  const [subscribers, setSubscribers] = useState(incomingSubscribers) // (mockData.templates);
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [selected, setSelected] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();
  const [snackMessage, setSnackMessage] = useState("");
  const displayColumns = [
    {
      field: 'name',
      label: 'Subscriber',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/lists/${params.row.id}/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'status',
      label: 'Status',
      minWidth:125,
      flex:1,
      editable: false,
    },
    {
      field: 'open_rate',
      label: 'Opens',
      minWidth:125,
      flex:1,
      editable: false,
    },
    {
      field: 'click_rate',
      label: 'Clicks',
      minWidth:125,
      flex:1,
      editable: false,
    },
    // {
    //   field: 'primary_email',
    //   label: 'Email',
    //   minWidth:125,
    //   flex:1,
    //   editable: false,
    //   renderCell: (params) => (
    //     params.row.name
    //   )
    // },
    {
      field: 'date_joined',
      label: 'Joined On',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (new Date(params.row.date_).toLocaleDateString())
    },
    // {
    //   field: 'actions',
    //   label: '',
    //   width:100,
    //   // flex:1,
    //   editable: false,
    //   renderCell: (params) => (<ListActions subscriber={params.row} setSnackMessage={setSnackMessage} setReloadLists={setReloadLists} />)
    // }
  ]

  console.log('subscribers', subscribers);
  console.log('list', list);

  // Set subscribers from incomingSubscribers
  useEffect(() => {
    if (incomingSubscribers) {
      setSubscribers(incomingSubscribers);
    }
  }, [incomingSubscribers])

  // Reset 'reloadLists' state to false after it is triggered
  useEffect(() => {
    if (reloadLists) {
      setReloadLists(false);
    }
  }, [reloadLists]);

  const handleDeleteClick = async (subscriberId) => {
    const deleted = await deleteList(subscriberId)
    if (deleted.status === 'success') {
      setReloadLists(true); // <-- Trigger api call to get subscribers again
    } else {
      console.log('NOT DELETED!');
    }
  };

  const handleCloseCreateTemplate = () => {
    setOpenCreateTemplate(false);
  }

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => subscribers.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }
  

  return (
    <Grid
      container
      style={{
        // height:'calc(100vh - 148px)', // Add or subtract 55px if the Footer Paginate comopnent is used
        justifyContent: 'center',
      }}
    >
      <Grid
        style={{
          // background:'#fff',
          borderRadius: 8,
          // padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width:'100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          // transition:'.4s',
          // maxWidth:1600
        }}
      >

        {/* <HeaderRow />
        <TableWrapper>
          {
            subscribers
              ?
                subscribers.map((subscriber, index) => (
                  <TableRow
                    subscriber={subscriber}
                    index={index}
                    key={subscriber.id}
                    onDeleteClick={() => handleDeleteClick(subscriber.id)}
                  />
                ))
              :
                <Typography>No custom fields have been created yet.</Typography>
          }
        </TableWrapper> */}



      </Grid>
        {
          !isSearching || subscribers.length
            ?
              // <DataGrid
              //   getRowId={(row) => row.id} 
              //   rows={subscribers?.length ? subscribers : []}
              //   columns={dataColumns}
              //   density="comfortable"
              //   disableVirtualization
              //   onRowSelectionModelChange={(ids) => {
              //     handleRowSelection(ids);
              //   }}
              //   initialState={{
              //     pagination: {
              //       paginationModel: {
              //         pageSize: 20,
              //       },
              //     },
              //   }}
              //   sx={{
              //     '&.MuiDataGrid-root':{
              //       borderBottom:'none',
              //       '& .MuiDataGrid-cell:focus': {
              //         outline: 'none',
              //         borderBottom:`2px solid ${theme.palette.primary.main}`,
              //         background:`${theme.palette.primary.light}15`
              //       }
              //     },
              //     ...fill_mui_scrollbar
              //   }}
              //   // pageSizeOptions={[5]}
              //   // autoPageSize
              //   checkboxSelection
              //   disableRowSelectionOnClick
              // />
              <FillTable
                title={list?.name ? list.name : 'Subscriber List'}
                rows={subscribers?.length ? subscribers : []}
                columns={displayColumns}
                // onRowClick={handleRowClick}
                setFilterBy={setFilterBy}
                pages={pages}
                selectedRows={selected}
                setSelectedRows={setSelected}
                handleRowSelection={handleRowSelection}
                actionCell={(row) => (
                  <ListActions subscriber={row} setSnackMessage={setSnackMessage} setReloadLists={setReloadLists} />
                )}
                renderCell={(row, column) => (
                  <Stack direction="row">
                    <RenderCell row={row} column={column} />
                  </Stack>
                )}
                searchButton={
                  <ReusableFilterSearch
                    useSearch
                    isOpen
                    placeholder="Search Lists"
                    content={subscribers}
                    setContent={setSubscribers}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    searchFields={['name']}
                  />
                }
                toolbarButtons={[
                  <CustomTooltip
                    title="Create List"
                  >
                    <Grid
                      sx={{
                        display:'flex',
                        alignItems:'center'
                      }}
                    >
                      <Button
                        href={`/lists/${list.id}/subscribers/import/`}
                        sx={{
                          textTransform:'none',
                          gap:1
                        }}
                      >
                        <AddSVG width={14} />
                        <Typography fontWeight={500}>Add Contacts</Typography>
                      </Button>
                      {/* <IconButton
                        href={`/lists/${list.id}/subscribers/import/`}
                        text={screenSize.sm ? null : "New List"}
                      >
                        <AddSVG width={18} />
                      </IconButton>
                      <Typography fontWeight={500}>Add Contacts</Typography> */}
                    </Grid>
                  </CustomTooltip>
                ]}
                // toolbarActions={
                //   <BulkActions
                //     selected={selected}
                //     setReloadSurveys={setReloadSurveys}
                //   />
                // }
                checkboxes={false}
                sx={{
                  ...fill_mui_scrollbar
                }}
              />

            :
              <Grid
                container
                style={{
                  justifyContent:'center',
                }}
              >
                <Typography>No Forms Found.</Typography>
              </Grid>
        }
      <div id='gjs' style={{display:'none'}}></div>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
        notice={snackMessage}
      />
    </Grid>
  )
}

export default ListSubscribers

const ListActions = (props) => {
  const { subscriber, setSnackMessage, setReloadLists } = props;
  // const subscriber = params.row;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleCloseConfirmRemove = () => {
    setOpenConfirmDelete(false);
    setRemoving(false);
  }

  const removeFromList = async () => {
    setRemoving(true);
    // const deletedTemplate = await removeFromList(subscriber.id) // <-- This api doesn't exist yet and not sure whether to use existing contact api method or if we want an api that handles more than just removing list from contact mailing_lists array
    // if (deletedTemplate.status === 'success') {
    //   setReloadLists(true); // <-- Reload the current forms to show the deletion
    //   setOpenConfirmDelete(false);
    //   setDeleted(true);
    //   setRemoving(false);
    //   setSnackMessage({ open: true, message: 'List deleted', severity: 'success' })
    // }
  }



  return (
    <Stack
      direction="row"
      spacing={.5}
    >
      <CustomTooltip
        title="Edit Contact"
      >
        <IconButton
          href={`/lists/${subscriber.mailing_list_id}/subscribers/${subscriber.id}/`}
        >
          <EditSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>

      <CustomTooltip
        title="Remove from list"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmRemove}
        header="Delete List"
        title={`You are about to remove ${subscriber.name ? `contact ${subscriber.name}` : 'this contact'} from the ${subscriber.mailing_list_name} list`}
        messages={{
          one:`Are you sure you want to remove ${subscriber.name ? `contact ${subscriber.name}` : 'this contact'} from the ${subscriber.mailing_list_name} list? The contact will be permanently removed.`,
          confirm:'Yes'
        }}
        deleting={removing}
        cancel={handleCloseConfirmRemove}
        confirm={removeFromList}
        icons

      />

    </Stack>

  )

}

const RenderCell = ({ row, column }) => {

  switch (column.field) {
    case 'name':
      return (
        <div style={{display:'flex', flexDirection:'column'}}>
          <a
            href={`/lists/${row.mailing_list_id}/subscribers/${row.id}`}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            {row.name}<br />
            {row.primary_email}
          </a>
        </div>
      )
    case 'status':
      return (
        <div>
          <TagButton
            bgColor={subscriberStatusEnum.find(x => x.status == row[column.field]).color}
            fontColor={subscriberStatusEnum.find(x => x.status == row[column.field]).text}
            outlined
            outlinedColor={subscriberStatusEnum.find(x => x.status == row[column.field]).color}
            label={subscriberStatusEnum.find(x => x.status == row[column.field]).label}
            noPointer
          />
        </div>
      )
    case 'open_rate':
      return (
        `${Math.round(row.open_rate)}%`
      )
    case 'click_rate':
      return (
        `${Math.round(row.click_rate)}%`
      )
    case 'primary_email':
      return (
        row.primary_email
      )
    case 'date_joined':
      return (
        row.date_joined
        // new Date(row.date_joined).toLocaleString('en-us', {month:'2-digit', day:'2-digit', year:'2-digit', hour12:true, hour:'numeric', minute:'2-digit'})
      )
    default:
      break;
  }
}
