import React, { useEffect, useState } from 'react'
import { Button, Grid, Typography, TextField, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import { DeleteSVG, FolderSVG, LockedSVG } from '../../assets/icons';
import { TagSimple } from '../ui/Tags';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import DialogCreate from '../ui/DialogCreate';
import { deleteFolder, postFolder } from '../../hooks/mutations';

const Folders = (props) => {
  const { incomingFolders } = props;
  const [folders, setFolders] = useState(incomingFolders);
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
  });

  useEffect(() => {
    if (incomingFolders) {
      setFolders(incomingFolders);
    } 
  }, [incomingFolders])

  return (
    <Grid
      container
      sx={{
        justifyContent:'center'
      }}
    >
      <Grid
        container
        sx={{
          justifyContent:'space-between',
          m:1
        }}
      >
        <ReusableFilterSearch
          useSearch
          isOpen
          placeholder="Search Folders"
          content={folders}
          setContent={setFolders}
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          searchFields={['name']}
        />
        <Button variant="contained" onClick={() => setOpenCreateFolder(true)}>Create Folder</Button>
      </Grid>
      <Grid
        container
        sx={{
          // maxWidth:{ts:'100%', md:600, lg:800, xl:900}
          // justifyContent:'center'
        }}
      >
        {
          folders?.map(folder => (
            <FolderItemLong folder={folder} setFolders={setFolders} />
          ))
        }
      </Grid>
      <DialogCreate
        open={openCreateFolder}
        onClose={() => setOpenCreateFolder(false)}
        hideButtons
        header="Create Folder"
        // title="Create An Email Folder"
        messages={{
          // one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
        }}
      >
        <CreateFolderDialog onClose={() => setOpenCreateFolder(false)} setFolders={setFolders} />
      </DialogCreate>

    </Grid>
  )
}

export default Folders

const FolderItemLong = ({ folder, setFolders }) => {

  const handleDeleteFolder = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const deletedFolder = await deleteFolder(folder.id);
    if (deletedFolder.status === "success") {
      setFolders(prev => prev.filter(x => x.id !== folder.id));
    }
  }

  return (
    <Grid
      key={folder.id}
      item
      sx={{
        p: 1,
      }}
      ts={12}
    >
      <a 
        href={`/storage/folders/${folder.id}/`} 
        style={{ color: 'inherit' }}
      >
        <Grid
          container
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            height: 67,
            background: '#fff',
            boxShadow: '0 0 10px #00000020',
            p: 2,
            borderRadius: 4,
          }}
        >
          <Grid 
            item 
            sx={{
              display: 'flex', 
              alignItems: 'center', 
              gap: 2,
            }}
            // xs={4}
          >
            <FolderSVG height={30} />
            <Typography>
              {folder.name}
            </Typography>
            {
              folder.is_public
                ? <TagSimple name="public" />
                : <TagSimple name="private" icon={<LockedSVG height={16} color="#ccc" />} />
            }
          </Grid>
          <Grid
            item
            sx={{
              display:'flex'
            }}
            xs={4}
          >
          </Grid>
          <Grid 
            item 
            sx={{ pr: 1 }}
            // xs={4}
          >
            {
              folder.name !== "Public" &&
              folder.name !== "Templates" &&
              folder.name !== "CustomFields"
                ?
                  <IconButton
                    onClick={handleDeleteFolder}
                  >
                    <DeleteSVG height={16} />
                  </IconButton>
                : null
            }
          </Grid>
        </Grid>
      </a>
    </Grid>
  )
}

const FolderItemSquare = ({ folder }) => {
  return (
    <Grid
      key={folder.id}
      item
      sx={{
        p:1,
      }}
      ts={12}
      xs={6}
      sm={4}
      md={3}
    >
      <a href={`/storage/folders/${folder.id}/`} style={{color:'inherit'}}>
        <Grid
          sx={{
            boxShadow:'0 0 10px #00000020',
            p:2,
            borderRadius:4
          }}
        >
          <FolderSVG width="40%" />
          <Typography
            sx={{
              textAlign:'center',
              width:'100%'
            }}
          >
            {folder.name}
          </Typography>
        </Grid>
      </a>
    </Grid>

  )
}

const CreateFolderDialog = ({ onClose, setFolders }) => {
  const [name, setName] = useState('');
  const [isPublic, setIsPublic] = useState(false);

  const handleCreateFolder = async () => {
    const folderObj = {
      payload: {
        name: name,
        is_public: isPublic
      }
    }

    const savedFolder = await postFolder(folderObj);
    if (savedFolder.status === "success") {
      console.log('savedFolder', savedFolder);
      setFolders(prev => [ ...prev, savedFolder.data ])
      onClose();
    }
  }

  return (
    <Grid container>
        <TextField
          autoFocus
          margin="dense"
          label="Folder Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isPublic}
              onChange={(e) => setIsPublic(e.target.checked)}
            />
          }
          label="Public"
        />
        <Grid
          container
          sx={{
            justifyContent:'space-between'
          }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleCreateFolder} variant="contained">Submit</Button>
        </Grid>
    </Grid>
  );
}