import React, { useEffect, useState } from 'react';
import SocialPostEdit from './SocialPostEdit';
import { useGetAllChannels, useGetSocialPost } from '../../hooks/mutations';
import { Grid, Typography, Button } from '@mui/material';
import { initPost } from '../../lib/Data';
import { useTheme } from '@emotion/react';
import { DjangoComponents } from '../../templates/DjangoStyles';

const SocialPostEditWrapper = (props) => {
  const { incomingPost } = props;
  // const incomingPost = useGetSocialPost(postId);
  const channels = useGetAllChannels();
  const [postEdit, setPostEdit] = useState(incomingPost ? incomingPost : initPost);
  const theme = useTheme();


  // Set existing post to post state
  useEffect(() => {
    if (incomingPost) {
      setPostEdit(incomingPost);
    }
  }, [incomingPost])

  // // Set the GET post state (For preview) every time the POST post state is updated
  // useEffect(() => {
  //   if (postEdit) {
  //     translateForPreview();
  //   }
  // }, [postEdit, channels])

  if (channels == null) {
      return <Typography>Loading...</Typography>;
    }

  return (
    <DjangoComponents inner>
      <Grid
        id="post-container"
        container
        style={{
          height: 'calc(100vh - 100px)' //parentHeight-60
        }}
      >
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            overflow: 'auto'
          }}
          xs
        >
          <Grid
            container
            style={{
              justifyContent: 'center',
            }}
          >
            {channels.length === 0 ? (
              <Grid item xs={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                <Typography variant="h6">No social media connections found.</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href="/socials/channels/"
                  style={{ marginTop: '10px' }}
                >
                  Add Social Media Connections
                </Button>
              </Grid>
            ) : (
              <SocialPostEdit
                post={postEdit}
                setPost={setPostEdit}
                channels={channels}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </DjangoComponents>
  )
}

export default SocialPostEditWrapper;