import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, FormControl, ListItemText, Checkbox, Typography, Box, Chip } from '@mui/material';
import { useTheme } from '@emotion/react';
import { uploadMedia, providerMapping, getCustomFieldFolder, createStorageFile, postSocialPost, putSocialPost } from '../../hooks/mutations';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { postStatusMapping } from '../../lib/Data';
import { useNavigate } from 'react-router-dom';
import FileDropZone from '../ui/FileDropZone';
import { DuplicateImageSVG } from '../../assets/icons';
import CustomTooltip from '../ui/CustomTooltip';
import Infotip from '../ui/Infotip';
import getChannelIcon from '../social_posts/SocialMediaCommons';

const SocialPostEdit = ({ post, setPost, channels }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [files, setFiles] = useState([]); // initial upload from FileDropZone
  const [media, setMedia] = useState([]); // Displayed media set to state after media is uploaded to server
  const [publishDate, setPublishDate] = useState(null);
  const [errors, setErrors] = useState({});

  console.log('post', post)
  console.log('files', files)
  console.log('media', media)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost({ ...post, [name]: value });
  };

  const handleChannelChange = (channel) => {
    const selectedChannels = post.channel_options.includes(channel.provider)
      ? post.channel_options.filter((id) => id !== channel.provider)
      : [...post.channel_options, channel.provider];
    setPost({ ...post, channel_options: selectedChannels });
  };

  // // Direct from input
  // const handleMediaUpload = async (e) => {
  //   const files = e.target.files;
  //   const uploadedMedia = await Promise.all(Array.from(files).map(async (file) => {
  //     try {
  //       // Save file to storage
  //       const storageFolder = await getCustomFieldFolder(); // Change to 'upload folder' when Gaurav adds that api
  //       const storageFolderId = storageFolder.folderId;
  //       const fileName = file.name;
  //       const formData = new FormData();
  //       formData.append('file', file);
  //       formData.append('name', fileName);
  //       formData.append('folder', storageFolderId);

  //       const storageFileObj = {
  //         folderId: storageFolderId,
  //         storageFilePayload: formData
  //       };

  //       // Create a new file with a new file id
  //       const storedImage = await createStorageFile(storageFileObj);
  //       if (storedImage.status === "success") {
  //         console.log('storedImage', storedImage.data);
  //         return storedImage.data;
  //       }
  //     } catch (error) {
  //       console.error('Error uploading media', error);
  //     }
  //   }));

  //   setMedia(uploadedMedia);
  //   setPost({ ...post, media: uploadedMedia });
  // };

  useEffect(() => {
    if (files) {
      handleMediaUpload();
    }
  }, [files])

  // Handle media upload from FileDropZone files state
  const handleMediaUpload = async () => {
    const uploadedMedia = await Promise.all(Array.from(files).map(async (file) => {
      try {
        // Save file to storage
        const storageFolder = await getCustomFieldFolder(); // Change to 'upload folder' when Gaurav adds that api
        const storageFolderId = storageFolder.folderId;
        console.log('file', file);
        const fileName = file.file.name;
        const formData = new FormData();
        formData.append('file', file.file);
        formData.append('name', fileName);
        formData.append('folder', storageFolderId);

        const storageFileObj = {
          folderId: storageFolderId,
          storageFilePayload: formData
        };

        // Create a new file with a new file id
        const storedImage = await createStorageFile(storageFileObj);
        if (storedImage.status === "success") {
          console.log('storedImage', storedImage.data);
          return storedImage.data;
        } else if (storedImage.status == "error") {
          console.log('Error uploading this one media: ', storedImage.error.response.data);
          if (JSON.stringify(storedImage.error.response.data) === '{"non_field_errors":["The fields name, folder must make a unique set."]}') {
            return {
              name: file.file.name, fileNameExists: true
            }
          } else {
            return {
              name: 'Error uploading media', unknownError:true
            }
          }
        }
      } catch (error) {
        console.error('Error uploading media', error);
      }
    }));

    const acceptedMediaForPost = uploadedMedia.filter(x => x.file);
    console.log('acceptedMediaForPost', acceptedMediaForPost);

    setMedia(uploadedMedia);
    setPost({ ...post, media: acceptedMediaForPost });
  };

  const handlePublishDateChange = (date) => {
    setPublishDate(date);
    setPost({ ...post, schedule_time: date });
  };

  const handleCreatePost = async () => {
    const newErrors = {};
    if (!post.content) {
      newErrors.content = 'Content is required';
    }
    if (post.channel_options.length === 0) {
      newErrors.channels = 'At least one channel must be selected';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const payload = {
        content: post.content,
        channels: post.channel_options,
        schedule_time: post.schedule_time,
        ...(media.length > 0 && { image_ids: media.map((item) => item.id) })
      };

      const response = await postSocialPost({ payload });
      if (response.status === 'success') {
        console.log('Post created/updated successfully:', response.data);
      } else {
        console.error('Error creating/updating post:', response.error);
      }
    }
  };

  const handleUpdatePost = async () => {
    const newErrors = {};
    if (!post.content) {
      newErrors.content = 'Content is required';
    }
    if (post.channel_options.length === 0) {
      newErrors.channels = 'At least one channel must be selected';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const payload = {
        content: post.content,
        channels: post.channel_options,
        schedule_time: post.schedule_time,
        ...(media.length > 0 && { image_ids: media.map((item) => item.id) })
      };

      const response = await putSocialPost({ payload, postId: post.id });
      if (response.status === 'success') {
        console.log('Post created/updated successfully:', response.data);
      } else {
        console.error('Error creating/updating post:', response.error);
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Social Media Post</Typography>
      </Grid>
      {post.id && (
        <Grid item xs={12}>
          <Typography variant="body1">Status:</Typography>
          <Box display="flex" alignItems="center" gap={1}>
            {postStatusMapping[post.status]}
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          label="Content"
          name="content"
          value={post.content}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={4}
          error={!!errors.content}
          helperText={errors.content}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.channels}>
          <Typography variant="body1">Channels</Typography>
          <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
            {channels.map((channel) => (
              <Chip
                key={channel.provider_id}
                icon={getChannelIcon(channel.provider)}
                label={providerMapping[channel.provider]}
                onClick={() => handleChannelChange(channel)}
                color={post.post_ids[channel.provider] ? 'primary' : 'default'}
                variant={post.post_ids[channel.provider] ? 'filled' : 'outlined'}
              />
            ))}
          </Box>
          {errors.channels && <Typography color="error">{errors.channels}</Typography>}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {
            media.map(img => (
              <Grid
                item
                sx={{
                  position:'relative',
                  p:2
                }}
                ts={12}
                sm={6}
                md={4}
              >
                <Grid
                  sx={{
                    background:'#fff',
                    borderRadius:4,
                    boxShadow:'0 0 10px #00000020',
                    p:2,
                    // overflow:'clip'
                  }}
                >
                  {
                    img.file 
                      ?
                        <img
                          src={img.file}
                          style={{
                            width:'100%',
                            height:'100%',
                            objectFit:'cover',
                            borderRadius:8
                          }}
                        />
                      :
                        <>
                          <Grid
                            sx={{
                              position:'relative',
                              display:'flex',
                              alignItems:'center',
                              justifyContent:'center',
                              width:'100%',
                              height:'100%',
                              p:2
                            }}
                          >
                            <Typography
                              sx={{
                                position:'absolute',
                                textAlign:'center',
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                gap:1,
                                px:2,
                                py:1,
                                fontWeight:600,
                                background: `${theme.palette.background.secondary}ee`
                              }}
                            >
                             File already exists
                            <Infotip
                              info
                              title="Media already exists"
                              tip="A file with this name has already been uploaded"
                              messages={{
                                one: "A file with this name has already been uploaded to the folder.",
                                two: "You can rename this file and upload again or find this file in your media library."
                              }}
                            />
                            </Typography>

                            <DuplicateImageSVG width={150} />
                          </Grid>
                        </>
                    }
                </Grid>
                <Typography
                  sx={{
                    textAlign:'center'
                  }}
                >
                  {img.fileNameExists ? `File name ${img.name} already exists` : img.name}
                </Typography>
              </Grid>
            ))
          }
          <FileDropZone
            setFile={setFiles}
            multiple
            message="Drag 'n' drop or click to select media"
          />
          {/* <Button
            variant="contained"
            component="label"
          >
            Upload Media
            <input
              type="file"
              hidden
              multiple
              onChange={handleMediaUpload}
            />
          </Button> */}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Schedule Publish Date"
            value={publishDate}
            onChange={handlePublishDateChange}
            renderInput={(props) => <TextField {...props} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        {(!post.id || ![1, 4, 5, 6].includes(post.status)) && (
          <Button
            variant="contained"
            color="primary"
            onClick={post.id ? handleUpdatePost : handleCreatePost}
          >
            {post.id ? 'Update Post' : 'Create Post'}
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate('/socials/posts')}
        >
          Back to List
        </Button>
      </Grid>
    </Grid>
  );
};

export default SocialPostEdit;