import React, { useEffect, useState, cloneElement } from 'react'
import { Button, Collapse, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useGetFilteredLists } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG,DeleteSVG, UsageSVG  } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { ButtonCustom } from '../ui/Buttons';
import { Link } from 'react-router-dom';
import { deleteList } from '../../hooks/mutations';
import ReusableModal from '../ui/ReusableModal';
import CustomTooltip from '../ui/CustomTooltip';
import DialogWarning from '../ui/DialogWarning';
import { DataGrid } from '@mui/x-data-grid';
import { mockData } from '../../lib/mockData';
import DialogCreate from '../ui/DialogCreate';
import Loading from '../ui/Loading';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import { TagButton } from '../ui/Tags';


const Lists = (props) => {
  const { incomingLists } = props;
  const [reloadLists, setReloadLists] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
  });
  const [pages, setPages] = useState({page: 1, count:null, next:null, prev:null});
  // const incomingLists = useGetFilteredLists({reload:reloadLists, filterBy:filterBy});
  const screenSize = useScreenSize();
  const [lists, setLists] = useState(incomingLists) // (mockData.templates);
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [selected, setSelected] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();
  const [snackMessage, setSnackMessage] = useState("");
  const displayColumns = [
    {
      field: 'name',
      label: 'List',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/lists/${params.row.id}/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'subscribers_count',
      label: 'Subscribers',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => ((params.row.subscribers_count))
    },
    {
      field: 'open_rate',
      label: 'Opens',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => ((params.row.open_rate))
    },
    {
      field: 'click_rate',
      label: 'Clicks',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => ((params.row.click_rate))
    },
    // {
    //   field: 'actions',
    //   label: '',
    //   width:100,
    //   // flex:1,
    //   editable: false,
    //   renderCell: (params) => (<ListActions list={params.row} setSnackMessage={setSnackMessage} setReloadLists={setReloadLists} />)
    // }
  ]

  // Reset 'reloadLists' state to false after it is triggered
  useEffect(() => {
    if (reloadLists) {
      setReloadLists(false);
    }
  }, [reloadLists]);

  // Set lists state to incoming data on load
  useEffect(() => {
    if (incomingLists) {
      setLists(incomingLists.sort((a, b) => {
        if(a.date_created < b.date_created) return 1;
        if(a.date_created > b.date_created) return -1;
        return 0;
      }));
      const next = incomingLists.next?.split('?page=')[1]?.split('&page')[0] || null;
      const prev = incomingLists.previous?.split('?page=')[1]?.split('&page')[0] || null;
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
      setPages({
        page: id,
        count: incomingLists.count,
        next: next,
        prev: prev
      })

      setResetSearch(false);
    }
  }, [incomingLists]);

  const handleDeleteClick = async (listId) => {
    const deleted = await deleteList(listId)
    if (deleted.status === 'success') {
      setReloadLists(true); // <-- Trigger api call to get lists again
    } else {
      console.log('NOT DELETED!');
    }
  };

  const handleCloseCreateTemplate = () => {
    setOpenCreateTemplate(false);
  }

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => lists.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }
  

  return (
    <Grid
      container
      style={{
        // height:'calc(100vh - 148px)', // Add or subtract 55px if the Footer Paginate comopnent is used
        justifyContent: 'center',
      }}
    >
      <Grid
        style={{
          // background:'#fff',
          borderRadius: 8,
          // padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width:'100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          // transition:'.4s',
          // maxWidth:1600
        }}
      >

        {/* <HeaderRow />
        <TableWrapper>
          {
            lists
              ?
                lists.map((list, index) => (
                  <TableRow
                    list={list}
                    index={index}
                    key={list.id}
                    onDeleteClick={() => handleDeleteClick(list.id)}
                  />
                ))
              :
                <Typography>No custom fields have been created yet.</Typography>
          }
        </TableWrapper> */}



      </Grid>
        {
          !isSearching || lists.length
            ?
              // <DataGrid
              //   getRowId={(row) => row.id} 
              //   rows={lists?.length ? lists : []}
              //   columns={dataColumns}
              //   density="comfortable"
              //   disableVirtualization
              //   onRowSelectionModelChange={(ids) => {
              //     handleRowSelection(ids);
              //   }}
              //   initialState={{
              //     pagination: {
              //       paginationModel: {
              //         pageSize: 20,
              //       },
              //     },
              //   }}
              //   sx={{
              //     '&.MuiDataGrid-root':{
              //       borderBottom:'none',
              //       '& .MuiDataGrid-cell:focus': {
              //         outline: 'none',
              //         borderBottom:`2px solid ${theme.palette.primary.main}`,
              //         background:`${theme.palette.primary.light}15`
              //       }
              //     },
              //     ...fill_mui_scrollbar
              //   }}
              //   // pageSizeOptions={[5]}
              //   // autoPageSize
              //   checkboxSelection
              //   disableRowSelectionOnClick
              // />
              <FillTable
                title="Lists"
                rows={lists?.length ? lists : []}
                columns={displayColumns}
                // onRowClick={handleRowClick}
                setFilterBy={setFilterBy}
                pages={pages}
                selectedRows={selected}
                setSelectedRows={setSelected}
                handleRowSelection={handleRowSelection}
                actionCell={(row) => (
                  <ListActions list={row} setSnackMessage={setSnackMessage} setReloadLists={setReloadLists} />
                )}
                renderCell={(row, column) => (
                  <Stack direction="row">
                    <RenderCell row={row} column={column} />
                  </Stack>
                )}
                searchButton={
                  <ReusableFilterSearch
                    useSearch
                    isOpen
                    placeholder="Search Lists"
                    content={lists}
                    setContent={setLists}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    searchFields={['name']}
                  />
                }
                toolbarButtons={[
                  <CustomTooltip
                    title="Create List"
                  >
                    <IconButton
                      href="/lists/add/"
                      text={screenSize.sm ? null : "New List"}
                    >
                      <AddSVG width={18} />
                    </IconButton>
                  </CustomTooltip>
                ]}
                // toolbarActions={
                //   <BulkActions
                //     selected={selected}
                //     setReloadSurveys={setReloadSurveys}
                //   />
                // }
                checkboxes={false}
                sx={{
                  ...fill_mui_scrollbar
                }}
              />

            :
              <Grid
                container
                style={{
                  justifyContent:'center',
                }}
              >
                <Typography>No Forms Found.</Typography>
              </Grid>
        }
      <div id='gjs' style={{display:'none'}}></div>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
        notice={snackMessage}
      />
    </Grid>
  )
}

export default Lists

const ListActions = (props) => {
  const { list, setSnackMessage, setReloadLists } = props;
  // const list = params.row;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }


  const deleteTemplate = async () => {
    setDeleting(true);
    const deletedTemplate = await deleteList(list.id)
    if (deletedTemplate.status === 'success') {
      setReloadLists(true); // <-- Reload the current forms to show the deletion
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
      setSnackMessage({ open: true, message: 'List deleted', severity: 'success' })
    }
  }



  return (
    <Stack
      direction="row"
      spacing={.5}
    >
      <CustomTooltip
        title="Edit List"
      >
        <IconButton
          href={`/lists/${list.id}/edit/`}
        >
          <EditSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>

      <CustomTooltip
        title="Delete List"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete List"
        title={`You are about to delete the ${list.name} email template`}
        messages={{
          one:'Are you sure you want to delete this template? It will be permanently deleted.',
          confirm:'Yes'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={deleteTemplate}
        icons

      />

    </Stack>

  )

}

const RenderCell = ({ row, column }) => {
  switch (column.field) {
    case 'name':
      return (
        <a
          href={`/lists/${row.id}/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {row.name}
        </a>
      )
    case 'subscribers_count':
      return (
        <a
          href={`/lists/${row.id}/subscribers/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {row.subscribers_count}
        </a>
      )
    case 'open_rate':
      return (
        `${Math.round(row.open_rate)}%`
      )
    case 'click_rate':
      return (
        `${Math.round(row.click_rate)}%`
      )
    default:
      break;
  }
}
