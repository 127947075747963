import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import Folders from '../components/folders/Folders'
import { useGetAllFolders } from '../hooks/mutations'

const FoldersPage = () => {
  const incomingFolders = useGetAllFolders();
  return (
    // <MainPageTemplate title="CRM">
      <Folders incomingFolders={incomingFolders} />
    // </MainPageTemplate>
  )
}

export default FoldersPage