import React, { useEffect, useState, cloneElement } from 'react'
import { Button, Dialog, Divider, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { deleteSocialPost, putSocialPost, useGetSocialPosts, useGetFilteredSocialPosts } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG, DeleteSVG, CheckSVG, SelectSVG, RemoveFillSVG, ChannelsSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { ButtonCustom } from '../ui/Buttons';
import DialogWarning from '../ui/DialogWarning';
import { DataGrid } from '@mui/x-data-grid';
import CustomTooltip from '../ui/CustomTooltip';
import ReusableModal from '../ui/ReusableModal';
import Loading from '../ui/Loading';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import ActionContent from '../ui/ActionContent';

const SocialPosts = () => {
  const [reloadPosts, setReloadPosts] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
  });
  const [pages, setPages] = useState({ page: 1, count: null, next: null, prev: null });
  const incomingPosts = useGetFilteredSocialPosts({ reload: reloadPosts, filterBy: filterBy });
  const screenSize = useScreenSize();
  const [posts, setPosts] = useState(incomingPosts);
  const [resetSearch, setResetSearch] = useState(true);
  const [selected, setSelected] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();

  // Reset 'reloadPosts' state to false after it is triggered
  useEffect(() => {
    if (reloadPosts) {
      setReloadPosts(false);
    }
  }, [reloadPosts])

  // Set posts state to incoming data on load
  useEffect(() => {
    if (incomingPosts) {
      setPosts(incomingPosts.results);
      const next = incomingPosts.next?.split('?page=')[1]?.split('&page')[0] || null;
      const prev = incomingPosts.previous?.split('?page=')[1]?.split('&page')[0] || null;
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1);
      setPages({
        page: id,
        count: incomingPosts.count,
        next: next,
        prev: prev
      })

      setResetSearch(false);
    }
  }, [incomingPosts]);

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => posts.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }

  const displayColumns = [
    {
      field: 'content',
      label: 'Content',
      minWidth: 125,
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/socials/posts/${params.row.id}/edit/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {params.row.content}
        </a>
      )
    },
    {
      field: 'status',
      label: 'Status',
      minWidth: 125,
      flex: 1,
      editable: false,
    },
    {
      field: 'published_date',
      label: 'Published Date',
      minWidth: 125,
      flex: 1,
      editable: false,
    },
    // {
    //   field: 'actions',
    //   label: '',
    //   width: 100,
    //   editable: false,
    //   renderCell: (params) => (<PostActions
    //     post={params.row}
    //     setReloadPosts={setReloadPosts} />)
    // }
  ]

  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
      }}
    >
      <Grid
        style={{
          borderRadius: 8,
          width: '100%',
        }}
      >
      </Grid>
      {
        !isSearching || posts.length
          ?
          <FillTable
            title="Posts List"
            rows={posts?.length ? posts : []}
            columns={displayColumns}
            setFilterBy={setFilterBy}
            pages={pages}
            selectedRows={selected}
            setSelectedRows={setSelected}
            handleRowSelection={handleRowSelection}
            actionCell={(row) => (
              <PostActions post={row} setReloadPosts={setReloadPosts} />
            )}
            renderCell={(row, column) => (
              <Stack direction="row">
                <RenderCell row={row} column={column} />
              </Stack>
            )}
            searchButton={
              <ReusableFilterSearch
                useSearch
                isOpen
                placeholder="Search Posts"
                content={posts}
                setContent={setPosts}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                searchFields={['content']}
              />
            }
            toolbarButtons={[
              <CustomTooltip
                title="Manage Channels"
              >
                <IconButton
                  href="/socials/channels/"
                  text={screenSize.sm ? null : "Manage Channels"}
                >
                  <ChannelsSVG color1={theme.palette.tertiary.main} color2={theme.palette.tertiary.main} width={18} />
                </IconButton>
              </CustomTooltip>,
              <CustomTooltip
                title="Create Post"
              >
                <IconButton
                  href="/socials/posts/create/"
                  text={screenSize.sm ? null : "New Post"}
                >
                  <AddSVG width={18} />
                </IconButton>
              </CustomTooltip>
            ]}
            sx={{
              ...fill_mui_scrollbar
            }}
          />
          :
          <Grid
            container
            style={{
              justifyContent: 'center',
            }}
          >
            <Typography>No Posts Found.</Typography>
          </Grid>
      }
    </Grid>
  )
}

export default SocialPosts

const PostActions = (props) => {
  const { post, setReloadPosts } = props;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  const removePost = async () => {
    setDeleting(true);
    const deletedPost = await deleteSocialPost(post.id)
    if (deletedPost.status === 'success') {
      setReloadPosts(true);
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
    }
  }

  return (
    <Stack
      direction="row"
      spacing={.5}
    >
      <CustomTooltip
        title="Edit post"
      >
        <IconButton
          href={`/socials/posts/${post.id}/edit/`}
        >
          <EditSVG height={16} style={{ width: 20 }} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip
        title="Delete post"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={16} style={{ width: 20 }} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete Post"
        title={`You are about to delete the post with content: ${post.content}`}
        messages={{
          one: 'Are you sure you want to delete this post? It will be permanently deleted.',
          confirm: 'Yes'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={removePost}
        icons
      />
    </Stack>
  )
}

const RenderCell = ({ row, column }) => {
  switch (column.field) {
    case 'content':
      return (
        <a
          href={`/socials/posts/${row.id}/view/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {row.content}
        </a>
      )
    case 'status':
      return (
        row.status
      )
    case 'published_date':
      return (
        row.published_date
      )
    default:
      break;
  }
}