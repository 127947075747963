import { FacebookSVG, InstagramSVG, LinkedInSVG, XSVG } from '../../assets/social-icons';

const getChannelIcon = (provider) => {
  switch (provider) {
    case 1:
      return <FacebookSVG width={16} />;
    case 2:
      return <XSVG width={16} />;
    case 3:
      return <LinkedInSVG width={16} />;
    case 4:
      return <InstagramSVG width={16} />;
    default:
      return null;
  }
};

export default getChannelIcon;