import React, { useEffect, useState, cloneElement } from 'react'
import { Button, CircularProgress, Grid, IconButton, InputAdornment, Menu, Stack, Typography } from '@mui/material';
import { AddSVG, CheckmarkSVG, CloseRoundedSVG, DeleteSVG, DownTriangleSVG, EmailSVG, EventSVG, FileSVG, FilterSVG, GallerySVG, LinkSVG, SelectSVG, NumberSVG, PhoneSVG, RichTextSVG, SearchSVG, TextSVG, UploadSVG, VisibleSVG, WarningSVG, ListSVG, EditSVG, CloseFilledSVG } from '../../assets/icons';

import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import CustomTooltip from '../ui/CustomTooltip';
import DialogWarning from '../ui/DialogWarning';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import { deleteCustomField, putCustomField, useGetAllCustomFields, useGetCustomFields, useGetFilteredCustomFields } from '../../hooks/mutations';
import CustomColorPicker from '../ui/CustomColorPicker';
import { InputField } from '../ui/Fields';
import { key } from 'localforage';
import { translateFieldType } from '../../lib/Functions';
import DialogCreate from '../ui/DialogCreate';
import DialogAction from '../ui/DialogAction';
import CreateCustomFields from './CreateCustomFields';
import Papa from 'papaparse';
import ImportCsvHeaders from '../import/ImportCsvHeaders';
import CustomFieldComparison from './CustomFieldComparison';
import SnackbarWrapper from '../ui/SnackbarWrapper';

const displayColumns = [
  {
    field: 'field_name',
    label: 'Name',
    // minWidth: 125,
    // flex: 1,
    editable: false,
  },
  {
    field: 'data_type',
    label: 'Type',
    // minWidth: 125,
    // flex: 1,
    editable: false,
  },
  // {
  //   field: 'actions',
  //   label: '',
  //   // width: 130,
  //   editable: false,
  // }
];

const CustomFieldsList = () => {
  const [reloadCustomFields, setReloadCustomFields] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
    field_name: '',
    data_type: ''
  });
  const [pages, setPages] = useState({page: 1, count:null, next:null, prev:null});
  const incomingCustomFields = useGetFilteredCustomFields({reload:reloadCustomFields, filterBy:filterBy});
  const screenSize = useScreenSize();
  const [customFields, setCustomFields] = useState(incomingCustomFields);
  const [newCustomFields, setNewCustomFields] = useState([]);
  const [resetSearch, setResetSearch] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [selected, setSelected] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [expandedCustomFields, setExpandedCustomFields] = useState(true);
  const existingNameArray = customFields ? customFields.map(x => x.field_name) : [];
  const theme = useTheme();

  console.log('customFields', customFields);

  useEffect(() => {
    if (reloadCustomFields) {
      setReloadCustomFields(false);
    }
  }, [reloadCustomFields])

  // Set customFields state to incoming data on load
  useEffect(() => {
    console.log('incomingCustomFields', incomingCustomFields);
    if (incomingCustomFields) {
      setCustomFields(incomingCustomFields.results);
      const next = incomingCustomFields.next?.split('?page=')[1]?.split('&page')[0] || null;
      const prev = incomingCustomFields.previous?.split('?page=')[1]?.split('&page')[0] || null;
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
      setPages({
        page: id,
        count: incomingCustomFields.count,
        next: next,
        prev: prev
      })

      setResetSearch(false);
    }
  }, [incomingCustomFields]);


  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => customFields.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }

  // ADD TAG BUTTON MENU
  const [anchorEl, setAnchorEl] = useState(null);
  const isAddingCustomField = Boolean(anchorEl);
  const handleAddCustomFieldClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddCustomFieldClose = () => {
    setAnchorEl(null);
  };

  // ADD CUSTOM FIELD
  const [isAddingField, setIsAddingField] = useState(false);
  
  useEffect(() => {
    if (newCustomFields.length) {
      setIsAddingField(true);
    }
  }, [newCustomFields])
  
  const cancelNewFields = () => {
    if (setIsAddingField) {
      setIsAddingField(false);
    }
    setNewCustomFields([]);
    // resetCsvFile();
  };

  const handleCloseNewFields = () => {
    setIsAddingField(false);
  }


  // IMPORT HEADERS AS CUSTOM FIELDS
  const [headers, setHeaders] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [isImportingHeaders, setIsImportingHeaders] = useState(false);

  console.log('headers', headers);
  const handleCloseImportingHeaders = () => {
    resetCsvFile();
    setIsImportingHeaders(false);
  }

  // Handle uploading of csv file
  useEffect(() => {
    if (csvFile) {
      handleUpload();
    }
  }, [csvFile]);

  const handleUpload = async () => {
    if (!csvFile) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const csvText = e.target.result;
      const { data } = Papa.parse(csvText, { header: false });
      const headerData = data[0];
      setHeaders(headerData);
    };

    reader.readAsText(csvFile.file);
  }

  const resetCsvFile = () => {
    setIsImportingHeaders(false);
    setCsvFile(null);
    setHeaders(null);
    setNewCustomFields([]);
  }


  return (
    !isSearching || customFields.length
      ?
        <FillTable
          title="Custom Field List"
          rows={customFields?.length ? customFields : []}
          columns={displayColumns}
          setFilterBy={setFilterBy}
          pages={pages}
          selectedRows={selected}
          setSelectedRows={setSelected}
          handleRowSelection={handleRowSelection}
          checkboxes={false}
          dense
          actionCell={(row) => (
            <CustomFieldActions customField={row} setSnackMessage={setSnackMessage} setReloadCustomFields={setReloadCustomFields} existingNameArray={existingNameArray} />
          )}
          renderCell={(row, column) => (
            <Stack direction="row">
              <RenderCell row={row} column={column} setReloadCustomFields={setReloadCustomFields} />
            </Stack>
          )}
          searchButton={
            <ReusableFilterSearch
              useSearch
              isOpen
              placeholder="Search Field Names"
              content={customFields} // incomingCustomFields
              setContent={setCustomFields}
              filterBy={filterBy}
              setFilterBy={setFilterBy}
              searchFields={['field_name']}
            />
          }
          toolbarButtons={[
            <CustomTooltip
              title="Import fields from csv headers"
            >
              <Button
                onClick={() => setIsImportingHeaders(true)}
                sx={{
                  gap:1,
                  fontWeight:600
                }}
              >
                <UploadSVG width={screenSize.sm ? 20 : 18} />
                {screenSize.sm ? null : "Import From CSV"}
              </Button>
            </CustomTooltip>,

            <>
              <CustomTooltip
                title="Create CustomField"
              >
                <Button
                  onClick={() => setIsAddingField(true)}
                  sx={{
                    gap:1,
                    fontWeight:600
                  }}
                >
                  <AddSVG width={screenSize.sm ? 16 : 14} />
                  {screenSize.sm ? null : "Add Field"}
                </Button>
              </CustomTooltip>

              <DialogAction
                open={isAddingField}
                onClose={handleCloseNewFields}
                hideButtons
                header="Create Custom Field"
                icon={<AddSVG width={16} color1={theme.palette.primary.main} />}
                // title="Create An Email Template"
                // messages={{
                //   confirm:'Save',
                //   cancel:'Cancel'
                // }}
                // cancel={handleCancelEdit}
                // confirm={saveField}
                // disabled={notUnique || editField.field_name.trim().length < 1}
              >
                <CreateCustomFields
                  setReload={setReloadCustomFields}
                  setIsAddingField={setIsAddingField}
                  incomingNewCustomFields={newCustomFields}
                  // resetCsvFile={resetCsvFile}
                  setSnackMessage={setSnackMessage}
                />
              </DialogAction>

              <DialogAction
                open={isImportingHeaders}
                onClose={handleCloseImportingHeaders}
                hideButtons
                header="Import Fields From CSV"
                icon={<AddSVG width={16} color1={theme.palette.primary.main} />}
                fullWidth
                maxWidth="xl"
                // title="Create An Email Template"
                // messages={{
                //   confirm:'Save',
                //   cancel:'Cancel'
                // }}
                // cancel={handleCancelEdit}
                // confirm={saveField}
                // disabled={notUnique || editField.field_name.trim().length < 1}
              >
              {
                !headers
                  ?
                    <ImportCsvHeaders
                      setHeaders={setHeaders}
                      csvFile={csvFile}
                      setCsvFile={setCsvFile}
                      resetCsvFile={resetCsvFile}
                    />
                  :
                    <CustomFieldComparison
                      setIsImportingHeaders={setIsImportingHeaders}
                      customFields={[ {field_name: 'Name'}, {field_name: 'Email'}, ...customFields || [] ]}
                      headers={headers}
                      newCustomFields={newCustomFields}
                      setNewCustomFields={setNewCustomFields}
                      resetCsvFile={resetCsvFile}
                    />
              }

              </DialogAction>

              <SnackbarWrapper
                onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
                notice={snackMessage}
              />

            </>
          ]}
          // toolbarActions={
          //   <BulkActions
          //     selected={selected}
          //     setReloadCustomFields={setReloadCustomFields}
          //   />
          // }
          sx={{
            ...fill_mui_scrollbar
          }}
        />

      :
        <Typography>No customFields have been created yet.</Typography>
  )
}

export default CustomFieldsList

const CustomFieldActions = (props) => {
  const { customField, setSnackMessage, setReloadCustomFields, existingNameArray } = props;
  // const customField = params.row;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [editField, setEditField] = useState(customField);
  const [openEdit, setOpenEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [notUnique, setNotUnique] = useState(false);
  const theme = useTheme();
  
  // DELETING CUSTOM FIELD
  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  const removeCustomField = async () => {
    setDeleting(true);
    const deletedField = await deleteCustomField(customField.id);
    if (deletedField.status === 'success') {
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
      setReloadCustomFields(true);
      setSnackMessage({ open: true, message: 'Custom Field deleted', severity: 'success' })
    }
  }

  // EDITING CUSTOM FIELD
  const saveField = async () => {
    try {
      setSaving(true);
      const customFieldObj = {
        customFieldId: editField.id,
        payload: editField
      }
      const savedField = await putCustomField (customFieldObj);
  
      if (savedField.status === 'success') {
        setOpenEdit(false);
        setSaved(true);
        setSaving(false);
        handleCloseEdit(true);
        setReloadCustomFields(true);
        setSnackMessage({ open: true, message: 'Custom Field updated', severity: 'success' })
      }
    }

    catch (error) {
      console.log('error', error)
    }
  }
  
  const handleEditName = (value) => {
    const selectedFieldArray = existingNameArray.filter(x => x !== customField.field_name);
    const uniqueExistingCheck = selectedFieldArray.includes(value);

    if (uniqueExistingCheck) {
      setNotUnique(true);
    } else {
      setNotUnique(false);
    }

    setEditField({ ...editField, field_name: value })
  }

  const handleEditEnum = (value, enum_index) => {
    const newField = { ...customField };
    const enums = newField.enum_values;
    enums[enum_index] = value;
    setEditField({ ...editField, enum_values: enums})
  }

  const handleAddEnum = () => {
    const newField = { ...customField };
    const enums = [...newField.enum_values, ''];
    setEditField({ ...editField, enum_values: enums})
  }

  const handleDeleteEnum = (option) => {
    const newField = { ...editField };
    const updatedEnums = newField.enum_values.filter(x => x !== option)
    setEditField({ ...editField, enum_values: updatedEnums})
  }

  const handleCloseEdit = () => {
    setOpenEdit(false);
  }

  const handleCancelEdit = () => {
    setEditField(customField);
    setOpenEdit(false);
  }


  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={.5}
    >
      <CustomTooltip
        title="Edit custom field"
      >
        <IconButton
          onClick={() => setOpenEdit(true)}
        >
          <EditSVG height={18} style={{ width: 20 }} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip
        title="Delete custom field"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={18} style={{ width: 20 }} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete Custom Field"
        title={<>You are about to delete the <span style={{fontWeight:600}}>{customField.field_name}</span> field</>}
        messages={{
          one: <>This will remove the custom field from all contacts that currently use it. All information stored in this field will be permanently lost. <span style={{fontWeight:500, fontStyle:'italic'}}>This cannot be undone</span>.</>,
          confirm: 'Yes'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={removeCustomField}
        icons
      />

      <DialogAction
        open={openEdit}
        onClose={handleCloseEdit}
        // hideButtons
        header="Edit Custom Field"
        icon={<EditSVG width={16} color1={theme.palette.primary.main} />}
        // title="Create An Email Template"
        messages={{
          confirm:'Save',
          cancel:'Cancel'
          // one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
        }}
        icons={{
          confirm:<></>,
          cancel:<CloseRoundedSVG width={12} color1={theme.palette.mid.main} />
        }}
        cancel={handleCancelEdit}
        confirm={saveField}
        disabled={notUnique || editField.field_name.trim().length < 1}
      >
        {/* <Grid
          container
          style={{
            padding:32,
            paddingTop:16,
            width:'100%',
            // flexDirection:'column',
            // justifyContent:'center',
            alignItems:'center'
          }}
        > */}
          {/* <Grid
            container
            style={{
              display:'flex',
              gap:8,
              justifyContent:'center',
              paddingBottom:16
            }}
          >
            <EditSVG width={18} color1={theme.palette.primary.main} />
            <Typography
              style={{
                textTransform:'uppercase',
                fontWeight:700,
                color: theme.palette.primary.main
              }}
            >
              Edit Custom Field
            </Typography>
          </Grid> */}

          <InputField
            label='Field Name'
            placeholder='Field Name'
            value={editField.field_name}
            onChange={(e) => handleEditName(e.target.value)}
            margin={notUnique || !editField.field_name.length ? false : true}
          />
          <Grid
            container
            style={{
              marginBottom: notUnique || !editField.field_name.length ? 16 : 0

            }}
          >
            {
              notUnique
                ?
                  <>
                    <Typography
                      color="warning.main"
                      style={{
                        marginTop:8,
                      }}
                    >
                      That field already exists.
                    </Typography>
                  </>
                :
                  null
            }
            {
              !editField.field_name.length
                ?
                  <>
                    <Typography
                      color="warning.main"
                      style={{
                        marginTop:8
                      }}
                    >
                      Names cannot be blank.
                    </Typography>
                  </>
                :
                  null
            }

          </Grid>


          {
            editField.enum_values
              ?
                <>
                  <Typography
                    style={{
                      margin:'0 16px',
                      fontWeight:600,
                      color: theme.palette.primary.main
                    }}
                  >
                    Options
                  </Typography>
                  {
                    editField.enum_values.map((value, enum_index) => (
                      <InputField
                        key={enum_index}
                        // label='Field Name'
                        placeholder='Field Name'
                        value={value}
                        onChange={(e) => handleEditEnum(e.target.value, enum_index)}
                        marginAmount="4px 0"
                        customInputProps={{
                          endAdornment: <InputAdornment position="end">
                            <IconButton
                              onClick={() => handleDeleteEnum(value)}
                            >
                              <CloseFilledSVG
                                width={20}
                                color1={theme.palette.mid.main}
                              />
                            </IconButton>
                          </InputAdornment>,
                        }}
                      />
                    ))
                  }
                  <Button
                    onClick={handleAddEnum}
                    // variant="outlined"
                    startIcon={<AddSVG width={10} color1={theme.palette.primary.main} />}
                    fullWidth
                  >
                    Add Option
                  </Button>
                </>
              :
                <InputField
                  label='Default Value'
                  placeholder='Default Value'
                  value={editField.field_default_value}
                  onChange={(e) => setEditField({ ...editField, field_default_value: e.target.value })}
                  margin
                />

          }

          {/* <Grid
            container
            style={{
              marginTop:16,
              justifyContent:'space-between'
            }}
          >
            <Button
              color="mid"
              variant="outlined"
              startIcon={<CloseRoundedSVG width={12} color1={theme.palette.mid.main} />}
              onClick={handleCloseEdit}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={notUnique}
              startIcon={saving ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : null } //<DeleteSVG width={12} color1="#fff" />}
              onClick={saveField}
            >
              {saving ? 'Saving' : 'Save'}
            </Button>
          </Grid> */}
        {/* </Grid> */}

      </DialogAction>


    </Stack>

  )
}

// const CustomFieldEdit = (props) => {
//   const {  } = props;
  
//   return (
//     <Grid
//       container
//     >
//       <Grid
//         item
//         xs={12}
//         sm
//       >
//         <EditSVG width={18} />
//         <ColorOutlineSVG width={18} />
//       </Grid>
//     </Grid>
//   )
// }

const RenderCell = ({ row, column, setReloadCustomFields }) => {
  const [customFieldData, setCustomFieldData] = useState(row);
  const [editable, setEditable] = useState(false);

  // Handle saving color only
  useEffect(() => {
    if (row.color != customFieldData.color) {
      editCustomField(customFieldData);
    }
  }, [customFieldData])

  useEffect(() => {
    if (row) {
      setCustomFieldData(row)
    }
  }, [row])

  // Function to change color (used in CustomColorPicker)
  const handleColor = (value) => {
    setCustomFieldData({
      ...customFieldData,
      color: value
    });
  }

  // Function to change name (after input blurs)
  const handleName = (e) => {
    setCustomFieldData({
      ...customFieldData,
      name: e.target.value.replace(' ', '-').toLowerCase()
    })
  }

  // API to update customField and reload customFields api
  const editCustomField = async (data) => {
    const customFieldObj = {
      customFieldId: row.id,
      payload: data
    }

    const updatedCustomField = await putCustomField(customFieldObj);
    if (updatedCustomField.status === "success") {
      console.log('successful custom field update');
      if (setReloadCustomFields) {
        setReloadCustomFields(true);
      }
    }
  }



  switch (column.field) {
    case 'field_name':
      return (
        customFieldData.field_name
        // <InputField
        //   readOnly={!editable}
        //   noAutoFocus
        //   onFocus={() => setEditable(true)}
        //   onBlur={() => {
        //     setEditable(false);
        //     if (customFieldData.name != row.name) {
        //       editCustomField(customFieldData);
        //     }
        //   }}
        //   value={customFieldData.name}
        //   onChange={(e) => handleName(e)}
        //   onKeyDown={(e) => {
        //     if (e.key === "Enter") {
        //       editCustomField(customFieldData);
        //     }
        //   }}
        //   singleLine
        // />
      )
      case 'data_type':
        return (
          <Grid container>
            <Grid
              style={{
                width:50,
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                marginLeft:-10
              }}
            >
              {cloneElement(selectedIcons[customFieldData.data_type].icon, {color1:'#777', color:'#777'})}
            </Grid>
            {translateFieldType(customFieldData.data_type)}

          </Grid>
        )
        case 'color':
        return (
          <CustomColorPicker color={customFieldData.color} setColor={handleColor} />
        )
      default:
      break;
  }
}

export const selectedIcons = {
  text: {label: 'text', icon: <TextSVG width={18} />}, // <Typography style={{fontStyle:'italic', lineHeight:1, textTransform:'uppercase', fontWeight:800}}>T</Typography>,
  number: {label: 'number', icon: <NumberSVG width={28} />}, //<Typography style={{lineHeight:1, fontSize:'.75rem', fontWeight:900}}>123</Typography>,
  email: {label: 'email', icon: <EmailSVG width={18} />},
  date: {label: 'date', icon: <EventSVG width={18} />},
  enum: {label: 'select', icon: <SelectSVG width={15} />},
  list: {label: 'list', icon: <ListSVG width={13} />},
  bool: {label: 'checkbox', icon: <CheckmarkSVG width={16} />},
  image_file: {label: 'image', icon: <GallerySVG width={20} />},
  file: {label: 'file', icon: <FileSVG width={11} />},
  url: {label: 'link', icon: <LinkSVG width={17} />},
  large_text_field: {label: 'rich text', icon: <RichTextSVG width={26} />},
  phone_number: {label: 'phone', icon: <PhoneSVG width={11} />},
}
