import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Box, Chip } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useGetSocialPost, providerMapping } from '../../hooks/mutations';
import { FacebookSVG, InstagramSVG, LinkedInSVG, XSVG } from '../../assets/social-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { postStatusMapping } from '../../lib/Data';
import getChannelIcon from './SocialMediaCommons';

const SocialPostDisplay = ({ postId }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { reactPostId } = useParams();
  const [post, setPost] = useState(null);
  const incomingPost = useGetSocialPost(postId || reactPostId);

  useEffect(() => {
    if (incomingPost) {
      setPost(incomingPost);
    }
  }, [incomingPost]);


  const getPostLink = (provider, postId) => {
    switch (provider) {
      case 1:
        return `https://www.facebook.com/${postId}`;
      case 2:
        return `https://twitter.com/statuses/${postId}`;
      case 3:
        return `https://www.linkedin.com/feed/update/${postId}`;
      case 4:
        return `https://www.instagram.com/p/${postId}`;
      default:
        return '#';
    }
  };

  if (!post) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Social Media Post</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{post.content}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">Status:</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          {postStatusMapping[post.status]}
          {/* <Typography variant="body1">{postStatusMapping[post.status]}</Typography> */}
        </Box>
      </Grid>
      <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Channels Published:</Typography>
          <Box display="flex" flexWrap="wrap" gap={1} mt={1}>
            {Object.keys(post.post_ids).map((provider) => (
              <Chip
                key={provider}
                icon={getChannelIcon(parseInt(provider))}
                label={providerMapping[parseInt(provider)]}
                color="primary"
                component="a"
                href={getPostLink(parseInt(provider), post.post_ids[provider])}
                target="_blank"
                clickable
              />
            ))}
          </Box>
        </Grid>
      <Grid item xs={12}>
        {post.images && post.images.map((image, index) => (
          <Box key={index} sx={{ width: 100, height: 100, overflow: 'hidden', marginRight: 1 }}>
            <img src={image.file} alt={image.name} style={{ width: '100%', height: 'auto' }} />
          </Box>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/socials/posts/${post.id}/edit`)}
        >
          Edit Post
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate('/socials/posts')}
        >
          Back to List
        </Button>
      </Grid>
    </Grid>
  );
};

export default SocialPostDisplay;