import React from 'react';
import { useParams } from 'react-router-dom';
import SocialPostDisplay from '../components/social_posts/SocialPostDisplay';
import MainPageTemplate from '../templates/MainPageTemplate';

const SocialPostDisplayPage = () => {
  const { reactPostId } = useParams();

  return (
    <MainPageTemplate
      title="Social Posts"
      breadcrumbs={[
        'social_posts',
        'social_posts_display',
        // {type:'social_posts_edit', label: incomingPost?.name, url: `/social_posts/${incomingPost?.id}/`},
      ]}
    >
      <SocialPostDisplay postId={reactPostId} />
    </MainPageTemplate>
  );
};

export default SocialPostDisplayPage;