import React, { useEffect, useState, cloneElement } from 'react'
import { Button, Collapse, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useGetAllCampaigns, useGetEmailTemplates, useGetFilteredEmailTemplates } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG,DeleteSVG  } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { ButtonCustom } from '../ui/Buttons';
import { emailTemplateColumns } from '../../lib/Data';
import { Link } from 'react-router-dom';
import { deleteEmailTemplate } from '../../hooks/mutations';
import ReusableModal from '../ui/ReusableModal';
import CustomTooltip from '../ui/CustomTooltip';
import DialogWarning from '../ui/DialogWarning';
import { DataGrid } from '@mui/x-data-grid';
import { mockData } from '../../lib/mockData';
import DialogCreate from '../ui/DialogCreate';
import Loading from '../ui/Loading';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';


const EmailTemplates = () => {
  const [reloadEmailTemplates, setReloadEmailTemplates] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
  });
  const [pages, setPages] = useState({page: 1, count:null, next:null, prev:null});
  const incomingEmailTemplates = useGetFilteredEmailTemplates({reload:reloadEmailTemplates, filterBy:filterBy});
  const campaigns = useGetAllCampaigns();
  const screenSize = useScreenSize();
  const [emailTemplates, setEmailTemplates] = useState(incomingEmailTemplates) // (mockData.templates);
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [selected, setSelected] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();
  const [snackMessage, setSnackMessage] = useState("");
  const displayColumns = [
    {
      field: 'name',
      label: 'Name',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/templates/${params.row.id}/`}
          style={{
            textDecoration:'none',
            color:'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'last_used_campaign_id',
      label: 'Last Campaign',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (campaigns.filter(x => x.id === params.row.last_used_campaign_id).name)
    },
    {
      field: 'modified',
      label: 'Last Update',
      minWidth:125,
      flex:1,
      editable: false,
      renderCell: (params) => (new Date(params.row.modified).toLocaleDateString())
    },
    // {
    //   field: 'actions',
    //   label: '',
    //   width:100,
    //   // flex:1,
    //   editable: false,
    //   renderCell: (params) => (<EmailTemplateActions emailTemplate={params.row} setSnackMessage={setSnackMessage} setReloadEmailTemplates={setReloadEmailTemplates} />)
    // }
  ]

  // Reset 'reloadEmailTemplates' state to false after it is triggered
  useEffect(() => {
    if (reloadEmailTemplates) {
      setReloadEmailTemplates(false);
    }
  }, [reloadEmailTemplates]);

  // Set emailTemplates state to incoming data on load
  useEffect(() => {
    if (incomingEmailTemplates) {
      setEmailTemplates(incomingEmailTemplates.results);
      const next = incomingEmailTemplates.next?.split('?page=')[1]?.split('&page')[0] || null;
      const prev = incomingEmailTemplates.previous?.split('?page=')[1]?.split('&page')[0] || null;
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
      setPages({
        page: id,
        count: incomingEmailTemplates.count,
        next: next,
        prev: prev
      })

      setResetSearch(false);
    }
  }, [incomingEmailTemplates]);

  const handleDeleteClick = async (emailTemplateId) => {
    const deleted = await deleteEmailTemplate(emailTemplateId)
    if (deleted.status === 'success') {
      setReloadEmailTemplates(true); // <-- Trigger api call to get emailTemplates again
    } else {
      console.log('NOT DELETED!');
    }
  };

  const handleCloseCreateTemplate = () => {
    setOpenCreateTemplate(false);
  }

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => emailTemplates.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }
  

  return (
    <Grid
      container
      style={{
        // height:'calc(100vh - 148px)', // Add or subtract 55px if the Footer Paginate comopnent is used
        justifyContent: 'center',
      }}
    >
      <Grid
        style={{
          // background:'#fff',
          borderRadius: 8,
          // padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width:'100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          // transition:'.4s',
          // maxWidth:1600
        }}
      >

        {/* <HeaderRow />
        <TableWrapper>
          {
            emailTemplates
              ?
                emailTemplates.map((emailTemplate, index) => (
                  <TableRow
                    emailTemplate={emailTemplate}
                    index={index}
                    key={emailTemplate.id}
                    onDeleteClick={() => handleDeleteClick(emailTemplate.id)}
                  />
                ))
              :
                <Typography>No custom fields have been created yet.</Typography>
          }
        </TableWrapper> */}



      </Grid>
        {
          !isSearching || emailTemplates.length
            ?
              // <DataGrid
              //   getRowId={(row) => row.id} 
              //   rows={emailTemplates?.length ? emailTemplates : []}
              //   columns={dataColumns}
              //   density="comfortable"
              //   disableVirtualization
              //   onRowSelectionModelChange={(ids) => {
              //     handleRowSelection(ids);
              //   }}
              //   initialState={{
              //     pagination: {
              //       paginationModel: {
              //         pageSize: 20,
              //       },
              //     },
              //   }}
              //   sx={{
              //     '&.MuiDataGrid-root':{
              //       borderBottom:'none',
              //       '& .MuiDataGrid-cell:focus': {
              //         outline: 'none',
              //         borderBottom:`2px solid ${theme.palette.primary.main}`,
              //         background:`${theme.palette.primary.light}15`
              //       }
              //     },
              //     ...fill_mui_scrollbar
              //   }}
              //   // pageSizeOptions={[5]}
              //   // autoPageSize
              //   checkboxSelection
              //   disableRowSelectionOnClick
              // />
              <FillTable
                title="Email Template List"
                rows={emailTemplates?.length ? emailTemplates : []}
                columns={displayColumns}
                // onRowClick={handleRowClick}
                setFilterBy={setFilterBy}
                pages={pages}
                selectedRows={selected}
                setSelectedRows={setSelected}
                handleRowSelection={handleRowSelection}
                actionCell={(row) => (
                  <EmailTemplateActions emailTemplate={row} setSnackMessage={setSnackMessage} setReloadEmailTemplates={setReloadEmailTemplates} />
                )}
                renderCell={(row, column) => (
                  <Stack direction="row">
                    <RenderCell row={row} column={column} campaigns={campaigns} />
                  </Stack>
                )}
                searchButton={
                  <ReusableFilterSearch
                    useSearch
                    isOpen
                    placeholder="Search Email Templates"
                    content={emailTemplates}
                    setContent={setEmailTemplates}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    searchFields={['name']}
                  />
                }
                toolbarButtons={[
                  <CustomTooltip
                    title="Create Email Template"
                  >
                    <IconButton
                      href="/templates/new/"
                      // onClick={() => setOpenCreateTemplate(true)}
                      text={screenSize.sm ? null : "New Email Template"}
                    >
                      <AddSVG width={18} />
                    </IconButton>
                  </CustomTooltip>
                ]}
                // toolbarActions={
                //   <BulkActions
                //     selected={selected}
                //     setReloadSurveys={setReloadSurveys}
                //   />
                // }
                checkboxes={false}
                sx={{
                  ...fill_mui_scrollbar
                }}
              />

            :
              <Grid
                container
                style={{
                  justifyContent:'center',
                }}
              >
                <Typography>No Forms Found.</Typography>
              </Grid>
        }
      <div id='gjs' style={{display:'none'}}></div>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
        notice={snackMessage}
      />
    </Grid>
  )
}

export default EmailTemplates

const EmailTemplateActions = (props) => {
  const { emailTemplate, setSnackMessage, setReloadEmailTemplates } = props;
  // const emailTemplate = params.row;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }


  const deleteTemplate = async () => {
    setDeleting(true);
    const deletedTemplate = await deleteEmailTemplate(emailTemplate.id)
    if (deletedTemplate.status === 'success') {
      setReloadEmailTemplates(true); // <-- Reload the current forms to show the deletion
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
      setSnackMessage({ open: true, message: 'Template deleted', severity: 'success' })
    }
  }


  return (
    <Stack
      direction="row"
      spacing={.5}
    >
      <CustomTooltip
        title="Edit Template"
      >
        <IconButton
          href={`/templates/${emailTemplate.id}/`}
        >
          <EditSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>
      <CustomTooltip
        title="Delete Template"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={16} style={{width:20}} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete Email Template"
        title={`You are about to delete the ${emailTemplate.name} email template`}
        messages={{
          one:'Are you sure you want to delete this template? It will be permanently deleted.',
          confirm:'Yes'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={deleteTemplate}
        icons

      />

    </Stack>

  )

}

const RenderCell = ({ row, column, campaigns }) => {

  switch (column.field) {
    case 'name':
      return (
        <a
          href={`/templates/${row.id}/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {row.name}
        </a>
      )
    case 'last_used_campaign_id':
      return (
        campaigns?.find(x => x.id === row.last_used_campaign_id)
          ? campaigns.find(x => x.id === row.last_used_campaign_id).name
          : '— —'
      )
    case 'modified':
      return (
        new Date(row.modified).toLocaleDateString()
      )
    default:
      break;
  }
}
