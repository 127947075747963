export const status = {
  campaign: {
    '1': 'Sent',
    '2': 'Scheduled',
    '3': 'Draft',
    '4': 'Queued',
    '5': 'Delivering',
    '6': 'Paused'
  },
  contact: {
    '1': 'Pending',
    '2': 'Subscribed',
    '3': 'Unsubscribed',
    '4': 'Cleaned',
    '5': 'EmailBounced',
    '6': 'EmailReportedSpam', 
    '7': 'CRM Only'
  },
  imports: {
    '1': 'Pending',
    '2': 'Queued',
    '3': 'Importing',
    '4': 'Completed',
    '5': 'Errored',
    '6': 'Canceled'
  }
}

export const statusColors = {
  campaign: {
    '1': '#00A6A6',
    '2': '#00A6A6',
    '3': '#FFF6E5',
    '4': '#008EDB',
    '5': '#FF3333',
    '6': '#AAAAAA'
  },
  contact: {
    '1': '#AAAAAA',
    '2': '#00A6A6',
    '3': '#FFF6E5',
    '4': '#008EDB',
    '5': '#FF3333',
    '6': '#FF0000',
    '7': '#27ADE7'
  },
  imports: {
    '1': '#AAAAAA',
    '2': '#F78B1E',
    '3': '#27D8D3',
    '4': '#00A6A6',
    '5': '#FF0000',
    '6': '#000000'
  }
}

export const ChartColors = {
  default: [
    '#00A6A6',
    '#F78B1E',
    '#27ADE7',
    '#EFC899',
    '#7AE5CA'
  ]
}

export const initSurvey = {
  category_set: [
    {
      question_set: [],
      name: "default",
      order: 0,
      description: ""
    }
  ],
  name: "",
  description: "",
  redirect_url: "",
  // need_logged_user: true,
  // editable_answers: true,
  // display_method: 1,
  // expire_date: "2023-12-07",
  // created_by_user: 0,
  // status: 1,
  // create_date: "2023-12-07T04:41:50.694378Z",
  // update_date: "2023-12-07T02:45:14.547000Z"
}

export const initQuestionSet = {
  text: "",
  order: 0,
  required: true,
  type: "text",
  choice_set: []
}

export const initAnswerSet = {
  id:'',
  choice_text:''
}

export const initForm = {
  name:'',
  user_visible_name:'',
  user_visible_description:'',
  user_visible_icon:'',
  redirect_url:'',
  mailing_list_options:[],
  custom_field_options:[],
}

export const initFormResponse = {
 user_visible_name: "",
  user_visible_description: "",
  user_visible_icon: null,
  redirect_url: "",
  mailing_lists: [],
  custom_fields: []
}

export const initSenderDetail = {
  domain_name: '',
  default_from_email: '',
  default_from_name: '',
  website_address: ''
}

export const initProduct = {
  addons: []
}

export const initEmailTemplate = {
  body: {},
  root: {},
  sections: [
    {
      uid: "section-m0WWT10dczqTqj85",
      id: "column-1",
      type: "column-1",
      label: "1 Column",
      base: "section",
      class: "fill-eb-section",
      style: {},
      elements: [
        {
          type: "Text",
          label: "Text",
          id: "text",
          base: "element",
          style: {},
          wrapStyle: {
            fontSize: "16px",
            textAlign: "center"
          },
          uid: "MQZ4SwSDA5wktRPr",
          class: "fill-eb-text",
          columnIndex: 0,
          sectionId: "section-m0WWT10dczqTqj85",
          content: "<a href=\"{-{unsub}-}\">Unsubscribe</a>"
        }
      ]
    }
  ]
}

export const contactStatusEnum = [
  {status: 1, label: 'Pending', color:'#AAAAAA', text: '#FFFFFF'},
  {status: 2, label: 'Subscribed', color:'#00A6A6', text: '#FFFFFF'},
  {status: 3, label: 'Unsubscribed', color:'#b67ede', text: '#FFFFFF'},
  {status: 4, label: 'Cleaned', color:'#008EDB', text: '#FFFFFF'},
  {status: 5, label: 'Bounced', data_label: "EmailBounced", color:'#ff7d33', text: '#FFFFFF'},
  {status: 6, label: 'Reported Spam', data_label: 'EmailReportedSpam', color:'#FF3333', text: '#FFFFFF'},
  {status: 7, label: 'CRM Only', data_label: 'CRM Only', color:'#27ADE7', text: '#FFFFFF'},
]

export const campaignStatusEnum = [
  {status: 1, label: 'Sent', color:'#00A6A6', text: '#FFFFFF'},
  {status: 2, label: 'Scheduled', color:'#27ADE7', text: '#FFFFFF'},
  {status: 3, label: 'Draft', color:'#CC7300', text: '#FFFFFF'},
  {status: 4, label: 'Queued', color:'#EFC899', text: '#FFFFFF'},
  {status: 5, label: 'Delivering', color:'#8b00cc', text: '#FFFFFF'},
  {status: 6, label: 'Paused', color:'#d2214a', text: '#FFFFFF'},
]

export const subscriberStatusEnum = [
  {status: 1, label: 'Pending', color:'#AAAAAA', text: '#FFFFFF'},
  {status: 2, label: 'Subscribed', color:'#00A6A6', text: '#FFFFFF'},
  {status: 3, label: 'Unsubscribed', color:'#b67ede', text: '#FFFFFF'},
  {status: 4, label: 'Cleaned', color:'#008EDB', text: '#FFFFFF'},
  {status: 5, label: 'Bounced', data_label: "EmailBounced", color:'#ff7d33', text: '#FFFFFF'},
  {status: 6, label: 'Reported Spam', data_label: 'EmailReportedSpam', color:'#FF3333', text: '#FFFFFF'},
]

export const importStatusEnum = [
  {status: 1, label: 'Pending', color:'#AAAAAA', text: '#FFFFFF'},
  {status: 2, label: 'Queued', color:'#F78B1E', text: '#FFFFFF'},
  {status: 3, label: 'Importing', color:'#27D8D3', text: '#FFFFFF'},
  {status: 4, label: 'Completed', color:'#00A6A6', text: '#FFFFFF'},
  {status: 5, label: 'Errored', color:'#FF0000', text: '#FFFFFF'},
  {status: 6, label: 'Canceled', color:'#000000', text: '#FFFFFF'},
]

export const customFieldColumns = [
  {
    label: 'Name',
    id: 'field_name',
    width: null
  },
  {
    label: 'Type',
    id: 'data_type',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.075
  },
]

export const tagsColumns = [
  {
    label: 'Name',
    id: 'name',
    width: null
  },
  {
    label: 'Color',
    id: 'color',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.05
  },
]

export const surveyColumns = [
  {
    label: 'Name',
    id: 'name',
    width: null
  },
  {
    label: 'Description',
    id: 'description',
    width: null
  },
  {
    label: 'ID',
    id: 'id',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.1
  },
]

export const emailTemplateColumns = [
  {
    label: 'Name',
    id: 'name',
    width: null
  },
  {
    label: 'Description',
    id: 'description',
    width: null
  },
  {
    label: 'ID',
    id: 'id',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.1
  },
]

export const formColumns = [
  {
    label: 'Name',
    id: 'name',
    width: null
  },
  {
    label: 'Description',
    id: 'description',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.1
  },
]

export const campaignColumns = [
  {
    label: 'Campaign',
    id: 'name',
    width: null
  },
  {
    label: 'Status',
    id: 'status',
    width: null
  },
  {
    label: 'Opens',
    id: 'opens',
    width: null
  },
  {
    label: 'Clicks',
    id: 'clicks',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.1
  },
]

export const funnelColumns = [
  {
    label: 'Name',
    id: 'field_name',
    width: null
  },
  {
    label: 'Type',
    id: 'data_type',
    width: null
  },
  {
    label: '',
    id: 'actions',
    width: 12*.05
  },
]

export const initHeaderColumns = [
  {label:'Name', field:'name', columnType:'default', type:'text', id:'name'},
  {label:'Primary Email', field:'primary_email', columnType:'default', type:'text', id:'primary_email'},
  {label:'Lists', field:'mailing_lists', columnType:'default', type:'list', id:'mailing_lists'},
  {label:'Status', field:'status', columnType:'default', type:'status', id:'status'},
  {label:'Tags', field:'tags', columnType:'default', type:'tags', id:'tags'},
  {label:'Company', field:'company', columnType:'default', type:'text', id:'company'},
]

export const emptyNodeData = {
  trigger: {
    // id: , <-- id is set during the update phase -- NOT HERE
    // position <-- position is inherited from original node data during the update phase
    type: 'trigger',
    data: {
      label: 'Trigger'
    },
    sourcePosition: 'bottom',
    targetPosition: 'top'
  },
  action: {
    // id: , <-- id is set during the update phase -- NOT HERE
    // position <-- position is inherited from original node data during the update phase
    type: 'action',
    data: {
      label: 'Action'
    },
    sourcePosition: 'bottom',
    targetPosition: 'top'
  },
  condition: {
    // id: , <-- id is set during the update phase -- NOT HERE
    // position <-- position is inherited from original node data during the update phase
    type: 'condition',
    data: {
      label: 'Condition'
    },
    sourcePosition: 'bottom',
    targetPosition: 'top'
  }
}

export const industryList = [
  {
    name: 'Retail & E-commerce',
    shortName: 'Retail',
    hash: 'retail',
    img: `https://images.pexels.com/photos/6214452/pexels-photo-6214452.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Preferred Products/Brands',
      'Average Purchase Value',
      'Loyalty Program Status',
      'Shopping Preferences (Online/In-Store)',
      'Last Purchase Date',
      'Preferred Payment Method',
      'Discount Eligibility',
      'Cart Abandonment History',
      'Wishlist Items',
      'Purchase Frequency',
      'Referral Source'
    ]
  },
  {
    name: 'Marketing Agencies',
    shortName: 'Marketing',
    hash: 'marketing',
    img: `https://images.pexels.com/photos/6120174/pexels-photo-6120174.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Campaigns Engaged In',
      'Preferred Communication Channel',
      'Budget for Marketing',
      'Service Type (SEO, PPC, Social Media)',
      'Competitors',
      'Project Start Date',
      'ROI Metrics',
      'Preferred Content Type',
      'Ad Spend Cap',
      'Target Audience Demographics',
      'Account Manager Assigned'
    ]
  },
  {
    name: 'Hospitality',
    shortName: 'Hospitality',
    hash: 'hospitality',
    img: `https://images.pexels.com/photos/19797319/pexels-photo-19797319/free-photo-of-2024.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Preferred Room Type',
      'Loyalty Program Tier',
      'Last Stay Date',
      'Dietary Preferences',
      'Booking Channel',
      'Special Requests',
      'Preferred Check-In Time',
      'Event Participation',
      'Average Length of Stay',
      'Preferred Payment Method',
      'VIP Status'
    ],
    sub_industries: [
      {
        name: 'Hotels',
        shortName: 'Hotels'
      },
      {
        name: 'Restaurants',
        shortName: 'Restaurants'
      },
      {
        name: 'Travel',
        shortName: 'Travel'
      },
    ]
  },
  {
    name: 'Real Estate',
    shortName: 'Estate',
    hash: 'estate',
    img: `https://images.pexels.com/photos/12955837/pexels-photo-12955837.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Property Type',
      'Budget Range',
      'Interested Locations',
      'Preferred Contact Method',
      'Financing Status',
      'Preferred Property Features',
      'Mortgage Pre-Approval Status',
      'Purchase Timeline',
      'Investment Goals',
      'Agent Assigned',
      'Last Property Viewed'
    ]
  },
  {
    name: 'Education & Training',
    shortName: 'Education',
    hash: 'education',
    img: `https://images.pexels.com/photos/5554668/pexels-photo-5554668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Course Interest',
      'Education Level',
      'Preferred Learning Style',
      'Enrollment Date',
      'Preferred Communication Method',
      'Progress Status',
      'Training Goals',
      'Learning Pace',
      'Program Completion Date',
      'Certifications Earned',
      'Instructor Assigned'
    ]
  },
  {
    name: 'Nonprofits & Charities',
    shortName: 'Charities',
    hash: 'charity',
    img: `https://images.pexels.com/photos/8062062/pexels-photo-8062062.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Donation Amount',
      'Preferred Causes',
      'Volunteer Status',
      'Last Donation Date',
      'Membership Level',
      'Recurring Donation Status',
      'Event Participation',
      'Volunteer Hours Logged',
      'Preferred Communication Method',
      'Donor Recognition Preferences',
      'Fundraising Campaigns Supported'
    ]
  },
  {
    name: 'Legal Services',
    shortName: 'Legal Services',
    hash: 'legal',
    img: `https://images.pexels.com/photos/8112197/pexels-photo-8112197.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Case Type',
      'Legal Status',
      'Case Priority',
      'Attorney Assigned',
      'Last Hearing Date',
      'Case Budget',
      'Preferred Contact Method',
      'Client Goals',
      'Court Date',
      'Evidence Submitted',
      'Settlement Status'
    ]
  },
  {
    name: 'Accounting',
    shortName: 'Accounting',
    hash: 'accounting',
    img: `https://images.pexels.com/photos/8927451/pexels-photo-8927451.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Business Type',
      'Revenue Bracket',
      'Preferred Contact Method',
      'Tax Filing Status',
      'Last Tax Filing Date',
      'Accounting Software Used',
      'Year-End Closing Status',
      'Client Billing Cycle',
      'Payment Terms',
      'Account Manager Assigned',
      'Preferred Payment Method'
    ]
  },
  {
    name: 'Consulting',
    shortName: 'Consulting',
    hash: 'consulting',
    img: `https://images.pexels.com/photos/6203311/pexels-photo-6203311.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`, //https://images.pexels.com/photos/5554771/pexels-photo-5554771.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5
    properties: [
      'Service Type',
      'Consultation Date',
      'Preferred Communication Method',
      'Client Goals',
      'Project Timeline',
      'Budget Range',
      'Competitors',
      'Last Invoice Date',
      'Industry Focus',
      'Consultant Assigned',
      'Preferred Payment Method'
    ]
  },
  {
    name: 'Health & Fitness',
    shortName: 'Health',
    hash: 'health',
    img: `https://images.pexels.com/photos/5735595/pexels-photo-5735595.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Fitness Goals',
      'Health Conditions',
      'Preferred Exercise Type',
      'Last Appointment Date',
      'Membership Level',
      'Preferred Contact Method',
      'Trainer Assigned',
      'Nutritional Preferences',
      'Workout Frequency',
      'Class Participation History',
      'Progress Tracking Metrics'
    ],
    sub_industries: [
      {
        name: 'Gyms',
        shortName: 'Gyms'
      },
      {
        name: 'Yoga Studios',
        shortName: 'Yoga'
      },
      {
        name: 'Personal Trainers',
        shortName: 'Personal Trainers',
      },
    ]
  },
  {
    name: 'Events',
    shortName: 'Events',
    hash: 'events',
    img: `https://images.pexels.com/photos/5868957/pexels-photo-5868957.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Event Type',
      'Preferred Venue',
      'Event Date',
      'Guest Count',
      'Budget Range',
      'Catering Preferences',
      'Entertainment Type',
      'Preferred Contact Method',
      'Event Goals',
      'Vendor Assigned',
      'Last Event Attended'
    ]
  },
  {
    name: 'Entertainment',
    shortName: 'Entertainment',
    hash: 'entertainment',
    img: `https://images.pexels.com/photos/274937/pexels-photo-274937.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Preferred Genre',
      'Event Participation',
      'Last Event Attended',
      'VIP Status',
      'Preferred Communication Method',
      'Subscription Status',
      'Preferred Artists',
      'Ticket Purchase History',
      'Event Frequency',
      'Membership Level',
      'Preferred Venues'
    ],
    sub_industries: [
      {
        name: 'Production Companies',
        shortName: 'Production Companies',
      },
      {
        name: 'Theaters',
        shortName: 'Theaters',
      },
      {
        name: 'Artists',
        shortName: 'Artists',
      },
    ]
  },
  {
    name: 'Financial Services',
    shortName: 'Finance',
    hash: 'finance',
    img: `https://images.pexels.com/photos/6120167/pexels-photo-6120167.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Investment Goals',
      'Account Type',
      'Risk Tolerance',
      'Last Review Date',
      'Preferred Contact Method',
      'Financial Advisor Assigned',
      'Portfolio Value',
      'Retirement Goals',
      'Account Activity Level',
      'Preferred Investment Products',
      'Tax Filing Status'
    ]
  },
  {
    name: 'Automotive',
    shortName: 'Automotive',
    hash: 'automotive',
    img: `https://images.pexels.com/photos/1037995/pexels-photo-1037995.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Vehicle Make/Model',
      'Service History',
      'Preferred Service Type',
      'Vehicle Purchase Date',
      'Last Service Date',
      'Warranty Status',
      'Preferred Contact Method',
      'Lease/Ownership Status',
      'Preferred Payment Method',
      'Insurance Provider',
      'Technician Assigned'
    ]
  },
  {
    name: 'Construction ',
    shortName: 'Construction ',
    hash: 'construction',
    img: `https://images.pexels.com/photos/271667/pexels-photo-271667.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=5`,
    properties: [
      'Project Type',
      'Budget Range',
      'Preferred Contact Method',
      'Project Timeline',
      'Preferred Materials',
      'Site Location',
      'Permitting Status',
      'Contractor Assigned',
      'Last Inspection Date',
      'Client Goals',
      'Safety Compliance Status'
    ]
  },
]

export const industryDetails = {
  retail: {
    header: {
      title: 'CRM and Email Marketing for Retail and E-commerce',
      img: '',
      sell: ''
    },
    body: [
      {
        title: 'Made For You.',
        sell: 'Retail and e-commerce businesses thrive on customer relationships and repeat purchases. Our CRM and email marketing platform allows you to segment customers based on purchasing behavior, allowing for highly targeted promotions and personalized offers. For example, send abandoned cart reminders or recommend products based on browsing history, boosting sales and enhancing customer loyalty. Automate follow-up emails post-purchase to encourage reviews and future purchases.'
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Retail & E-commerce | Boost Sales with Targeted Campaigns',
      description: 'Discover how our CRM and email marketing platform helps retail and e-commerce businesses boost customer retention and increase sales with personalized promotions, automated abandoned cart emails, and post-purchase follow-ups.'
    }
  },
  marketing: {
    header: {
      title: 'CRM & Email Marketing for Marketing Agencies',
      img: '',
      sell: ''
    },
    body: [
      {
        title: 'Made For You.',
        sell: 'Marketing agencies need robust tools to manage multiple clients and campaigns. Our CRM helps track leads, clients, and campaign performance all in one place, streamlining your processes. Use our email marketing platform to create and automate targeted email campaigns for different clients, track engagement, and measure ROI, ensuring every client gets the attention they need, without the manual work.'
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Marketing Agencies | Manage Clients & Campaigns Efficiently',
      description: `Streamline your marketing agency's workflow with a CRM that tracks leads, clients, and campaigns. Use automated email marketing to manage multiple client campaigns, track ROI, and increase client satisfaction.`
    }
  },
  hospitality: {
    header: {
      title: 'CRM & Email Marketing for Hospitality',
      img: '',
      sell: ''
    },
    body: [
      {
        title: 'Made For You.',
        sell: 'For hotels, restaurants, and resorts, managing guest experiences is critical. Our CRM allows you to track guest preferences, ensuring personalized stays or dining experiences. Automate email campaigns for booking confirmations, special offers, and loyalty program updates. For example, send pre-arrival emails to upsell premium services or exclusive deals during local events, maximizing revenue per guest.'
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Hospitality | Elevate Guest Experiences & Increase Revenue',
      description: 'Our CRM and email marketing platform allows hotels, restaurants, and resorts to personalize guest experiences, automate booking confirmations, and promote exclusive offers. Build lasting guest relationships with ease.'      
    }
  },
  estate: {
    header: {
      title: 'CRM & Email Marketing for Real Estate',
      img: '',
      sell: ''
    },
    body: [
      {
        title: 'Made For You.',
        sell: 'In real estate, timely communication with potential buyers or sellers can make all the difference. Our platform helps you track interactions, schedule follow-ups, and send property recommendations automatically. With our email marketing tools, nurture leads by sending personalized property updates based on their preferences and automate reminders for open houses or meetings, saving you time and keeping prospects engaged.'
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Real Estate | Nurture Leads & Close More Deals',
      description: 'Grow your real estate business with a CRM that tracks client interactions and automates property recommendations. Engage prospects through personalized email marketing campaigns and streamline follow-ups.'      
    }
  },
  education: {
    header: {
      title: 'CRM & Email Marketing for Education & Training',
      img: '',
      sell: ''
    },
    body: [
      {
        title: 'Made For You.',
        sell: `Whether it's a school, tutoring service, or training company, communication with students and parents is key. Use our CRM to manage enrollments, track student progress, and send out targeted communications. Automate email campaigns to remind students about upcoming classes, share progress reports, or promote new courses, ensuring engagement and retention.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Education & Training | Boost Enrollment & Engagement',
      description: 'Manage student enrollments, track progress, and automate class reminders with our CRM and email marketing platform. Enhance communication with personalized course updates and engagement strategies.'
    }
  },
  charity: {
    header: {
      title: `CRM & Email Marketing for Nonprofits & Charities`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `Nonprofits often juggle fundraising, events, and donor relationships. Our CRM helps you track donor engagement, volunteer hours, and fundraising campaigns in one place. Use our email marketing platform to send personalized donation requests, updates on how donations are being used, and invitations to events, helping you build stronger relationships with your supporters and increase donations.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Nonprofits & Charities | Build Donor Relationships & Increase Fundraising',
      description: 'Strengthen donor engagement and streamline fundraising efforts with our CRM and email marketing tools. Automate personalized donation requests, event invites, and volunteer updates for maximum impact.'
    }
  },
  legal: {
    header: {
      title: `CRM & Email Marketing for Legal Services`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `Law firms need to manage client relationships and case milestones effectively. Our CRM lets you track each case’s progress and schedule automated reminders for important deadlines or client meetings. Use our email marketing tools to send updates on legal trends, offer client-specific updates on cases, or automate client feedback surveys post-case to enhance client satisfaction and build trust.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Legal Services | Manage Clients & Automate Case Updates',
      description: 'Improve client relationships and streamline case management with our CRM and email marketing platform. Automate legal updates, case milestones, and client feedback surveys to enhance client satisfaction.'
    }
  },
  accounting: {
    header: {
      title: `CRM & Email Marketing for Accounting`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `Accountants rely on keeping clients updated with tax deadlines and financial updates. Our CRM allows you to track client interactions and deadlines with ease. Automate reminders for tax filing dates, financial reporting, or personalized service offerings. Use email marketing to send regular updates on tax law changes, financial tips, and personalized reports, keeping your clients informed and improving client retention.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Accounting | Automate Client Communication & Boost Retention',
      description: 'Manage tax deadlines, client interactions, and financial reporting with ease using our CRM and email marketing tools. Automate personalized email updates, reminders, and financial tips to keep clients engaged.'
    }
  },
  consulting: {
    header: {
      title: `CRM & Email Marketing for Consulting`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `Consultants must manage multiple client projects and communications efficiently. Our CRM helps you track interactions, project milestones, and follow-up actions, ensuring nothing falls through the cracks. Automate emails to clients with project updates, new service offerings, or industry insights tailored to their needs, building long-term relationships and demonstrating continued value.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Consulting | Streamline Client Projects & Improve Engagement',
      description: 'Simplify project management and client communications with our CRM and email marketing platform. Automate client updates, nurture relationships, and send industry insights to grow your consulting business.'
    }
  },
  health: {
    header: {
      title: `CRM & Email Marketing for Health & Fitness`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `Health clubs, personal trainers, and wellness centers thrive on membership retention and personalized experiences. Use our CRM to track member progress, class bookings, and preferences, then automate personalized emails with workout reminders, class schedules, or motivational messages. Email marketing can also help you promote new programs, send health tips, and re-engage inactive members with special offers.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Health & Fitness | Increase Member Retention & Engagement',
      description: 'Retain more members and build personalized experiences with our CRM and email marketing platform. Automate class reminders, health tips, and re-engagement campaigns to keep your members motivated.'
    }
  },
  events: {
    header: {
      title: `CRM & Email Marketing for Events`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `Event planners need seamless communication before, during, and after events. Our CRM helps you manage attendees, vendors, and timelines, ensuring no detail is overlooked. Automate email marketing campaigns to send event invitations, reminders, post-event surveys, or follow-up offers, ensuring consistent engagement and a memorable experience for your clients and attendees alike.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Events | Manage Attendees & Automate Communication',
      description: 'Streamline event planning with our CRM that tracks attendees, vendors, and timelines. Automate invitations, reminders, and post-event follow-ups to ensure a memorable and engaging event experience.'
    }
  },
  entertainment: {
    header: {
      title: `CRM & Email Marketing for Entertainment`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `In the entertainment industry, audience engagement is key. Use our CRM to track ticket sales, fan interactions, and event dates. Automate email campaigns for new show releases, ticket discounts, and exclusive content for loyal fans. Whether you’re managing a theater production, concert, or film release, our platform helps you stay connected with your audience and drive attendance.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Entertainment | Engage Audiences & Promote Events',
      description: 'Build lasting fan relationships and drive attendance with our CRM and email marketing tools. Automate new event announcements, ticket sales, and exclusive content promotions for maximum engagement.'
    }
  },
  finance: {
    header: {
      title: `CRM & Email Marketing for Financial Services`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `Financial service providers can build trust by delivering personalized, timely communications. Use our CRM to manage client portfolios, track interactions, and set automated follow-ups. Email marketing helps you send personalized financial updates, investment tips, and reminders about key financial deadlines, all while staying compliant with regulatory requirements and fostering stronger client relationships.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Financial Services | Build Trust & Automate Client Communications',
      description: 'Strengthen client relationships and stay compliant with our CRM and email marketing platform. Automate personalized financial updates, investment tips, and reminders to improve client retention and trust.'
    }
  },
  automotive: {
    header: {
      title: `CRM & Email Marketing for Automotive`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `For car dealerships and automotive services, customer retention is vital. Our CRM helps track customer purchases, service history, and interactions, enabling personalized outreach. Automate email campaigns for service reminders, vehicle promotions, and loyalty rewards, keeping your customers engaged and encouraging repeat business. For example, send timely service reminders or offers for trade-in opportunities when a customer’s vehicle reaches a certain age.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Automotive | Boost Sales & Customer Retention',
      description: 'Increase automotive sales and improve customer retention with our CRM and email marketing platform. Automate service reminders, vehicle promotions, and loyalty offers to drive repeat business and engagement.'
    }
  },
  construction: {
    header: {
      title: `CRM & Email Marketing for Construction`,
      img: ``,
      sell: ``
    },
    body: [
      {
        title: 'Made For You.',
        sell: `Construction companies can manage client relationships and project timelines more efficiently with our CRM. Track project progress, key dates, and client communications all in one place. Automate email updates for clients about project milestones, new service offerings, or follow-up on completed projects, ensuring smooth communication and stronger client relationships throughout the construction process.`
      },
    ],
    meta: {
      title: 'CRM & Email Marketing for Construction | Streamline Projects & Improve Client Communication',
      description: 'Manage client relationships and project timelines effectively with our CRM. Automate project updates, service offerings, and post-project follow-ups to ensure smooth communication and client satisfaction.'
    }
  },
}

export const featureList = [
  {
    name: `Unlimited contacts`,
    shortName: `Unlimited Contacts`,
    hash: `unlimited-contacts`,
    short: `Expand your reach without limits.`,
    description: `There are no boundaries to the number of contacts you can manage, ensuring you can nurture relationships and grow your audience without constraints.`,
    icon: `unlimited-contacts`,
    tier: 'included',
    availability: `now`,
  },
  {
    name: `Email Templates`,
    shortName: `Email Templates`,
    hash: `email-templates`,
    short: `Make every message memorable.`,
    description: `Choose from our wide array of professionally designed email templates, customizable to reflect your brand identity, ensuring your emails captivate and engage your audience.`,
    icon: `email-love`,
    tier: 'included',
    availability: `now`,
  },
  {
    name: `Free Monthly Emails`,
    shortName: `Monthly Emails`,
    hash: `monthly-emails`,
    short: `Includes 10K emails to send every month.`,
    description: `Send up to 10,000 emails every month with your subscription. More emails can be added or removed, as needed, every month.`,
    icon: `10k-emails`,
    tier: 'included',
    availability: `now`,
  },
  {
    name: `Custom email builder`,
    shortName: `Email Builder`,
    hash: `email-builder`,
    short: `Personalize your communications with ease.`,
    description: `Design custom emails quick and easy with our drag and drop email builder. Tailored to your audience's preferences, fostering meaningful connections and driving engagement has never been easier.`,
    icon: `email-design`,
    tier: 'included',
    availability: `now`,
  },
  {
    name: `Automated email campaigns`,
    shortName: `Email Campaigns`,
    hash: `email-campaigns`,
    short: `Save time and boost efficiency.`,
    description: `Utilize Fill Marketing's automated email campaign feature to schedule and send targeted messages at the optimal times, nurturing leads and guiding them through the customer journey effortlessly.`,
    icon: `automated-campaigns`,
    tier: 'included',
    availability: `later`,
  },
  {
    name: `Client Relationship Management`,
    shortName: `CRM`,
    hash: `crn`,
    short: `Strengthen connections and drive growth.`,
    description: `Centralize customer data, track interactions, and gain valuable insights to enhance customer satisfaction and drive informed decision-making.`,
    icon: `relationship-management`,
    tier: 'included',
    availability: `now`,
  },
  {
    name: `Add contacts from Gmail`,
    shortName: `Gmail Addon`,
    hash: `gmail-addon`,
    short: `Add email senders directly to your contact list with our Gmail addon.`,
    description: `Download our Gmail add on and create and update contacts directly from your Gmail account. You can add individual contacts or entire cc lists at once.`,
    icon: `gmail-contact`,
    tier: 'included',
    availability: `now`,
  },
  {
    name: `Custom properties`,
    shortName: `Custom Properties`,
    hash: `custom-properties`,
    short: `Capture unique insights and segment your audience effectively.`,
    description: `Define custom properties on contacts, enabling targeted campaigns and personalized messaging based on specific criteria.`,
    icon: `custom-properties`,
    tier: 'included',
    availability: `now`,
  },
  {
    name: `Dedicated IP for email sending`,
    shortName: `Dedicated IP`,
    hash: `dedicated-ip`,
    short: `Ensure deliverability and reputation.`,
    description: `Leverage a dedicated IP for email sending to maintain control over your email reputation, maximize deliverability, and foster trust with your audience.`,
    icon: `dedicated-ip`,
    tier: 'addon',
    availability: `now`,
  },
  {
    name: `Role-based access`,
    shortName: `Role Access`,
    hash: `role-access`,
    short: `Safeguard sensitive data and streamline collaboration.`,
    description: `Define user roles and permissions, empowering your team to work efficiently while maintaining security and control.`,
    icon: `role-access`,
    tier: 'included',
    availability: `later`,
  },
  {
    name: `Personal onboarding`,
    shortName: `Personal Onboarding`,
    hash: `onboarding`,
    short: `We help you get everything set up.`,
    description: `Every customer gets a personal account manager to help set up their new account. That's right, a real person to work with you.`,
    icon: `onboarding`,
    tier: 'included',
    availability: `now`,
  },
  // {
  //   name: `Unlimited mailing lists`,
  //   short: `Segment your contacts quickly and effectively.`,
  //   description: `Create as many mailing lists as you need to effectively segment your audience. Immediately add users to a mailing from a subscription form, csv, or do it manually.`,
  //   icon: `subscription-forms`,
  //   tier: 'included',
  //   availability: `now`,
  // },
  {
    name: `Mailing list subscription forms`,
    shortName: `Subscription Forms`,
    hash: `subscription-forms`,
    short: `Grow your subscriber base effortlessly.`,
    description: `Create and embed mailing list subscription forms on your website, allowing visitors to opt-in and stay connected with your brand seamlessly.`,
    icon: `subscription-forms`,
    tier: 'included',
    availability: `now`,
  },
  // {
  //   name: `Send emails directly from CRM`,
  //   shortName: ``,
  //   short: `Streamline your workflow and enhance efficiency.`,
  //   description: `Send emails directly from your CRM system, ensuring seamless communication and eliminating the need for switching between platforms.`,
  //   icon: `crm-emails`,
  //   tier: 'included',
  //   availability: `later`,
  // },
  {
    name: `Double opt-in`,
    shortName: `Double Opt-in`,
    hash: `double-opt-in`,
    short: `Build trust and enhance deliverability.`,
    description: `Use double opt-in to confirm subscribers' intentions and ensure compliance with regulations, fostering meaningful engagement and better deliverability rates.`,
    icon: `double-optin`,
    tier: 'included',
    availability: `now`,
  },
  {
    name: `Build custom surveys`,
    shortName: `Custom Surveys`,
    hash: `custom-surveys`,
    short: `Gain valuable insights and feedback.`,
    description: `Create custom surveys tailored to your specific needs, enabling you to gather actionable data and make informed decisions to drive growth.`,
    icon: `custom-surveys`,
    tier: 'included',
    availability: `now`,
  },
  // {
  //   name: `Embedded surveys`,
  //   short: `Gather feedback and engage your audience wherever they are.`,
  //   description: `Send surveys directly in emails or embed them on your website, making it convenient for recipients to provide valuable input and insights.`,
  //   icon: ``,
  //   tier: 'included',
  //   availability: `soon`,
  // },
  {
    name: `Early access`,
    shortName: `Early Access`,
    hash: `early-access`,
    short: `Your feature requests will be a priority with early access.`,
    description: `Want a feature that isn't available yet? This is your chance to make those requests and have direct communication with the team.`,
    icon: `early-access`,
    tier: 'included',
    availability: `now`,
  },
  {
    name: `Additional emails`,
    shortName: `More Emails`,
    hash: `more-emails`,
    short: `Send as many emails as you need per month.`,
    description: `Increase or decrease the number of emails you need to send each month. All subscriptions include 5,000 emails for free.`,
    icon: `crm-emails`,
    tier: 'addon',
    availability: `now`,
  },
]

export const emailBuilderInstructions = [
  {
    name: 'Instruction Name 1',
    description: 'Instructions 1 description',
    steps: [
      {
        name: 'Step 1',
        description: 'Step 1 description'
      }
    ]
  },
  {
    name: 'Instruction Name 2',
    description: 'Instructions 2 description',
    steps: [
      {
        name: 'Step 2',
        description: 'Step 2 description'
      }
    ]
  }
]

export const constantTemplateVariables = [
  {
    field_name: 'unsub',
    user_visible_name: 'Unsubscribe Link',
  },
  {
    field_name: 'name',
    user_visible_name: 'Full Name',
  },
  {
    field_name: 'primary_email',
    user_visible_name: 'Email',
  },
]

export const activitiesData = [
  {
    id: 0,
    name: 'Subscribed',
    event: 'Subscribed to the List',
    color: 'blue'
    // 'mailing_list_name': m_name,
    // 'date': date  
  },
  {
    id: 1,
    name: 'Unsubscribed',
    event: 'Unsubscribed',
    // 'date': date
  },
  {
    id: 2,
    name: 'Unsubscribed Campaign',
    event: 'Unsubscribed campaign',
    // 'date': date,
    // 'campaign_name': c_name
  },
  {
    id: 3,
    name: 'Unsubscribed Mailing List',
    event: 'Unsubscribed mailing list',
    color: 'orange'
    // 'date': date,
    // 'mailing_list_name': m_name
  },
  {
    id: 4,
    name: 'Opened Email',
    event: 'Opened Email',
    // 'date': date,
    // 'campaign_name': c_name
  },
  {
    id: 5,
    name: 'Sent Email',
    event: 'Sent Email',
    color: 'green'
    // 'date': date,
    // 'campaign_name': c_name
  },
  {
    id: 6,
    name: 'Clicked Link',
    event: 'Clicked Link',
    // 'date': date,
    // 'link': link,
    // 'campaign_name': c_name
  },
  {
    id: 7,
    name: 'Imported',
    event: 'Imported',
    // 'date': date
  },
]

export const initPost = {
  id: '',
  content: '',
  schedule_time: null,
  status: 1,
  published_date: '',
  images: [],
  post_ids: {},
  error_log: {},
  channel_options: []
};

export const initPostResponse = {
  id: '',
  content: '',
  schedule_time: null,
  status: 1,
  published_date: '',
  images: [],
  post_ids: {},
  error_log: {},
  channels: []
};

export const postStatusMapping = {
  1: 'Published',
  2: 'Scheduled',
  3: 'Draft',
  4: 'Queued',
  5: 'Publishing',
  6: 'Errored'
};