import React from 'react'
import CustomFields from '../components/custom_fields/CustomFields'
import MainPageTemplate from '../templates/MainPageTemplate'
import CustomFieldsList from '../components/custom_fields/CustomFieldsList'

const CustomFieldsPage = () => {
  return (
    <MainPageTemplate title="Custom Fields">
      {/* <CustomFields /> */}
      <CustomFieldsList />
    </MainPageTemplate>
  )
}

export default CustomFieldsPage