import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import { Button, Grid, Typography } from '@mui/material'

const NavPage = () => {
  return (
    <MainPageTemplate title="Welcome">
      <Grid
        container
        height="calc(80vh)"
        flexDirection="column"
        // justifyContent="center"
        alignItems="center"
        gap={1}
      >
        {
          [
            {
              title:'CONTACT PAGES',
              href:'',
              isTitle:true
            },
            {
              title:'Add Contact',
              href:'contacts/add'
            },
            {
              title:'Edit Contact',
              href:'/edit-contact'
            },
            {
              title:'Custom Fields',
              href:'/contacts/custom_fields'
            },
            {
              title:'Contacts Bulk Tags',
              href:'/contacts/bulk-tags',
            },
            {
              title:'Tags List',
              href:'/tags/tags-list',
            },
            // {
            //   title:'Contacts',
            //   href:'/contacts',
            //   archived:true
            // },
            {
              title:'Contacts-CRM',
              href:'/contacts-crm'
            },
            // {
            //   title:'CRM',
            //   href:'/crm',
            //   archived:true
            // },
            {
              title:'CSV Upload',
              href:'/csv-upload'
            },
            {
              title:'CSV Queued',
              href:'/csv-queued'
            },
            {
              title:'CSV Imports',
              href:'/csv-imports'
            },
            {
              title:'Feedback Panel',
              href:'/feedback-panel'
            },
            {
              title:'CAMPAIGNS',
              href:'',
              isTitle:true
            },
            {
              title:'Campaigns',
              href:'/campaigns'
            },
            {
              title:'Campaign Edit',
              href:'/campaigns/edit'
            },
            {
              title:'Campaign Status',
              href:'/campaign-status'
            },
            {
              title:'FOLDERS',
              href:'',
              isTitle:true
            },
            {
              title:'Folders',
              href:'/storage/folders',
            },
            {
              title:'LISTS',
              href:'',
              isTitle:true
            },
            {
              title:'Lists',
              href:'/lists',
            },
            {
              title:'List Settings',
              href:'/lists/settings',
            },
            {
              title:'EMAIL TEMPLATES',
              href:'',
              isTitle:true
            },
            {
              title:'Email Templates',
              href:'/templates'
            },
            {
              title:'FORMS',
              href:'',
              isTitle:true
            },
            {
              title:'Forms',
              href:'/forms'
            },
            {
              title:'SURVEYS',
              href:'',
              isTitle:true
            },
            {
              title:'Surveys',
              href:'/surveys'
            },
            {
              title:'ACCOUNT PAGES',
              href:'',
              isTitle:true
            },
            {
              title:'Usage',
              href:'/accounts/usage'
            },
            {
              title:'Sender Details',
              href:'/settings/sender-details'
            },
            {
              title:'User Management',
              href:'/accounts/user-management'
            },
            {
              title:'Payment Portal',
              href:'/accounts/customer-portal'
            },
            {
              title:'LANDING PAGES',
              href:'',
              isTitle:true
            },
            {
              title:'Pricing',
              href:'/pricing'
            },
            {
              title:'Signup Wizard',
              href:'/signup'
            },
            {
              title:'Register',
              href:'/register'
            },
            {
              title:'Register Tenant',
              href:'/register_tenant'
            },
            {
              title:'Register Details',
              href:'/register_details'
            },
            {
              title:'Subscribe',
              href:'/subscribe'
            },
            {
              title:'MISC PAGES',
              href:'',
              isTitle:true
            },
            {
              title:'Side Menu',
              href:'/side-menu'
            },
            {
              title:'Terms',
              href:'/terms'
            },
            {
              title:'Automation',
              href:'/automation'
            },
            {
              title:'SINGLES',
              href:'',
              isTitle:true
            },
            {
              title:'TAG SINGLE',
              href:'/single-tag'
            },
            {
              title:'Social Media Posts',
              href:'/socials/posts'
            },
          ].map(item => (
            <LandingButton
              title={item.title}
              href={item.href}
              isTitle={item.isTitle}
              archived={item.archived}
            />
          ))
        }
      </Grid>
      <Typography textAlign="center">Disabled buttons are components that have been deprecated.</Typography>
    </MainPageTemplate>
  )
}

export default NavPage

const LandingButton = (props) => {
  const { title, href, isTitle=false, archived=false } = props;
  return (
    <Button
      href={href}
      variant={isTitle ? "text" : "contained"}
      disabled={archived}
      style={{
        width:250,
        // marginBottom:12
      }}
    >
      {title}
    </Button>
  )
}