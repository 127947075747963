import React, { useEffect, useState } from 'react'
import { useGetSocialPost } from '../hooks/mutations'
import MainPageTemplate from '../templates/MainPageTemplate'
import SocialPostEditWrapper from '../components/social_posts/SocialPostEditWrapper'
import { useParams } from 'react-router-dom'

const SocialPostsEditPage = () => {
  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const { reactPostId } = useParams();
  const [reload, setReload] = useState();
  const [postId, setPostId] = useState(currentEnv === "STAGING" ? reactPostId : null);

  // Get the script element by its id
  const scriptElement = document.getElementById('django-edit-post');

  if (scriptElement && !postId) {
    // Access the content of the script element
    const djangoPostString = scriptElement?.textContent;
  
    // Parse the JSON data
    const djangoPost = JSON.parse(djangoPostString);

    // Set the post id state from the JSON data
    setPostId(djangoPost.id)
  }

  const incomingPost = useGetSocialPost(postId);
  console.log('incomingPost', incomingPost)

  return (
    <MainPageTemplate
      title="Social Posts"
      breadcrumbs={[
        'socials/posts',
        {type:'social_posts_edit', label: incomingPost? incomingPost.name : 'new', url: `/socials/posts/${incomingPost?.id}/`},
      ]}
    >
      {/* { incomingPost && */}
        <SocialPostEditWrapper incomingPost={incomingPost} />
      {/* } */}
    </MainPageTemplate>
  )
}

export default SocialPostsEditPage